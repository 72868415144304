import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import {
  Row,
  Col,
  Nav,
  NavItem,
  Button,
  ButtonGroup
} from 'reactstrap';

import { getUser } from '../../../core/utils.js';
import Loading from '../../../components/Loading';
import WidgetOpsisPaceWeeks from '../../../widgets/opsis/PaceWeeks';

import isScreen from '../../../core/screenHelper';


class OpsisHome extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      hasChanged: false,
      isLoaded: false,
      forecasts: [],
      comparisons: []
    };

    this._isMounted = false;
    this.setTabs = this.setTabs.bind(this);
    this.onTabChange = this.onTabChange.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.setTabs();
  };

  setTabs() {
    getUser(function(err, user) {
      if(err) {
        console.log('error retreiving user');
      } else {
        var tabSelected = 0;
        if(process.env.NODE_ENV === 'development') {
          tabSelected = 0;
        }
        var tabs = [
          {
            id: 0,
            icon: 'fa-bullseye',
            name: 'Tracking'
          }
        ];

        //console.log(user);
        
        this.setState({
          user: user,
          tabs: tabs,
          tabSelected: tabSelected
        });
      }
    }.bind(this));
  }

  onTabChange(tabSelected) {
    this.setState({ tabSelected });
  }

  selectAdmin = (e) => {
    e.preventDefault();
    this.props.history.push('/app/opsis/admin');
  }

  render() {
  
    return (
      <div>

        <Helmet>
          <title>SH4: Forecast and Budget Tracking</title>
        </Helmet>

        <div className="menuContainer lg">

          <Row>
            <Col xs={12} sm={6} md={6} lg={6} xl={6}>
              <Nav className="mt-4">
                <NavItem className="nopad-bottom nomargin-bottom">
                  
                    <h5 className="text-danger">Opsis</h5>

                    {this.state.tabs ? (
                    <ButtonGroup>
                        <Button color="default" className="mr-xs" onClick={((e) => this.selectAdmin(e))}><i className="fa fa-cog fa-light fa-lg text-danger" /></Button>
                      {this.state.tabs.map((tab, i) =>
                        <Button key={i}
                          color={this.state.tabSelected === i ? 'danger' : 'default'} className="mr-xs" onClick={() => this.onTabChange(i)}
                          active={this.state.tabSelected === i}
                        ><i className={['fa-light','fa-lg',tab.icon,this.state.tabSelected === i ? 'text-default' : 'text-danger'].join(' ')} />
                        <span className="d-md-down-none gutter-left-fixed-1 gutter-right-fixed-1">{tab.name}</span></Button>
                      )}
                    </ButtonGroup>
                    ) : null}
                </NavItem>
              </Nav>
            </Col>
          </Row>
        </div>

        <div className={isScreen('xs') || isScreen('sm') ? 'm-1 mt-2' : 'm-3 mt-3'}>

          {this.state.tabSelected === -1 ? (
            <Loading loading={!this.state.isLoaded} error={this.state.error} pad={10} />
          ): null} 

          {this.state.tabSelected === 0 ? (
            <div>
              <WidgetOpsisPaceWeeks print={false} trends={true} />
            </div>
          ): null} 
        
        </div>


      </div>
    );  

  }
}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(OpsisHome));
