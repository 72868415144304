import React from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

import { nFormatter, isNumeric } from '../../core/utils';

class Displays extends React.Component {
  
  static propTypes = {
    a: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ]),
    b: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string
    ]),
    fixed: PropTypes.number,
    format: PropTypes.string.isRequired,
    inverse: PropTypes.bool,
    bzero: PropTypes.string,
    pre: PropTypes.string,
    blankIfZero: PropTypes.bool,
  };

  static defaultProps = {
    pre: null,
    blankIfZero: false,
    fixed: 0
  };

  constructor(props) {
    super(props);
    this.displayHtml = this.displayHtml.bind(this);
  }

  displayHtml() {

    let html = '';
    let a = this.props.a;
    let b = this.props.b;
    let inverse = this.props.inverse || false;
    let fixed = this.props.fixed || 0;

    let str = '';

    switch(this.props.format) {

      case 'percent':
        if(a && b) {
          a = parseFloat(a);
          b = parseFloat(b);
          
          if((a !== b) && (b > 0)) {

            var p = parseFloat((a-b)/b) * 100;
            if(p > 10000) {
              str = '&nbsp;';
            } else {
              str += p.toFixed(fixed).replace(/\B(?=(\d{3})+(?!\d))/g, ",") + '%';
              if(a > b) {
                if(inverse) {
                  str += ' <i class="fa fa-caret-down text-danger fa-lg"></i>';
                } else {
                  str += ' <i class="fa fa-caret-up text-success fa-lg"></i>';
                }
              } else {
                if(inverse) {
                  str += ' <i class="fa fa-caret-up text-success fa-lg"></i>';
                } else {
                  str += ' <i class="fa fa-caret-down text-danger fa-lg"></i>';
                }
              }
            }
					} else {
						if(b === 0) {
              if(this.props.bzero && (this.props.bzero === 'blank')) {
                str = '';
              } else {
                str = '∞';
              }
						} else {
							if(a === b) {
								str = '--';
							} else {
								str = '&nbsp;';
							}
						}
          }
          html = str;
        } else {

          str = '';
          if(a > 0) {
            if(this.props.bzero && (this.props.bzero === 'blank')) {
              str = '';
            } else {
              str += '∞';
            }
          } else {
            if(b === 0) {
              if(this.props.bzero && (this.props.bzero === 'blank')) {
                str = '';
              } else {
                str += '∞';
              }
            } else {
              str += parseFloat(a * 100).toFixed(fixed) + '%';
              while(str.charAt(0) === '-')
              {
                str = str.substr(1);
              }
              str += ' <i class="fa fa-caret-down text-danger fa-lg"></i>';
            }
            
          }
          html = str;
        }
        break;
      case 'simple-percent':
        if(!isNumeric(a)) {
          str = '--';
        } else {
          str = Math.abs(parseFloat(parseFloat(a) * 100)).toFixed(fixed) + '%';
          if(a > 0) {
            str += ' <i class="fa fa-caret-up text-success fa-lg"></i>';
          } else {
            str += ' <i class="fa fa-caret-down text-danger fa-lg"></i>';
          }
        }
        html = str;
        break;
      case 'gain-loss':
        str = nFormatter(Math.abs(a));
        if(a > 0) {
          str += ' <i class="fa fa-caret-up text-success fa-lg"></i>';
        } else {
          str += ' <i class="fa fa-caret-down text-dangerfa-lg"></i>';
        }
        html = str;
        break;
      case 'comma':
        a = parseFloat(a).toFixed(fixed);
        if(this.props.blankIfZero && (a === 0)) {
          html += '';
        } else {
          if(this.props.pre) {
            html = this.props.pre;
          }
          html += a.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
        break;
      case 'age':
        var start = moment(a);
        var end = moment(b);

        var years = start.diff(end, 'year');
        end.add(years, 'years');

        var months = start.diff(end, 'months');
        end.add(months, 'months');

        var days = start.diff(end, 'days');

        var age = '';

        if(years > 0) {
          age = years + ' years, ' + months + ' months';
        } else {
          if(months > 0) {
            age = months + ' months, ' + days + ' days';
          } else {
            age = days + ' days';
          }
        }

        html = age;
        break;
      default: 
        html = '?';
        break;

    }

    return {__html: html};
  }
  
  render() {
    return (
      <span dangerouslySetInnerHTML={this.displayHtml()}></span>
    );
  }
}

export default Displays;

