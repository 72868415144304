import React, { useState } from 'react';
import { Row, Col, Button, Table } from 'reactstrap';

import { formatNumber, terse } from '../../../../core/utils';

import Poster from '../../../../components/Poster';
import Displays from '../../../../components/Displays';

const SemrushKeyword = (props) => {

  const [expand, setExpand] = useState(false);

  const handleExpand = function() {
    setExpand(!expand);
    const section = document.querySelector('#block_' + props.keyword.slug);
    if(section) {
      section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    }
  }

  return (
    <div className={props.className}>
      
      <div className={expand ? 'bg-primary p-3 pt-4 pb-0':'pb-0'} id={'block_' + props.keyword.slug}>
          <div className="d-flex">
            <div className="flex mr-2">
              {props.keyword.show ? (
                <>
                  <Poster poster={props.keyword.show.poster} id={props.keyword.show.poster_id} w={125} className="border-right" />
                  <a href={'https://www.google.com/search?q=' + props.keyword.keyword} target="_blank" rel="noreferrer" className="text-black d-none">
                    <h5 className="mb-4 pl-4">{props.keyword.show.short_name || props.keyword.show.name}</h5>
                  </a>

                  <p className="fs-mini text-muted text-center mt-4">Daily Search<br/>Impressions</p>
                  <h4 className="text-center">
                  {props.keyword.paid_metrics && props.keyword.paid_metrics.impressions ? (
                    <Displays a={parseInt(props.keyword.paid_metrics.impressions)} format="comma" />
                  ) : (
                    <span>--</span>
                  )}
                  </h4>

                  <p className="fs-mini text-muted text-center mt-4">Daily Search<br/>Spend</p>
                  <h4 className="text-center">
                  {props.keyword.paid_metrics && props.keyword.paid_metrics.spend ? (
                    <span>$<Displays a={parseInt(props.keyword.paid_metrics.spend)} format="comma" /></span>
                  ) : (
                    <span>--</span>
                  )}
                  </h4>


                </>
              ) : (
                <h5 className={'text-capitalize ' + (expand ? ' fw-bold' : '')}>
                  <a href={'https://www.google.com/search?q=' + props.keyword.keyword} target="_blank" rel="noreferrer" className="text-black">
                    {props.keyword.paid_rank ? (<span className={expand ? 'text-white':'text-muted'}>{props.keyword.paid_rank}. </span>) : null}<span className={expand ? 'text-white':''}>{props.keyword.keyword.toUpperCase()}</span>
                  </a>
                </h5>
              )}
            </div>
            <div className="flex-grow">
              <Row className={expand ? 'bg-primary p-3 pt-4 pb-0':''} id={'block_' + props.keyword.slug}>
                <Col xs={12} sm={12} md={12} lg={4} xl={4} className="mb-2">
                  {!expand ? (
                    <div className="card p-4">
                      <h6 className="text-center pb-2">Organic Search</h6>
                      <h4 className="text-center d-none">
                        {props.keyword.organic.organic.placements.map((placement, x) =>
                          <span key={x}>
                              {formatNumber('X', placement, false)}
                              {x !== (props.keyword.organic.organic.placements.length-1) ? (
                                <span>, </span>
                              ) : null}
                          </span>
                        )}
                      </h4>
                      {props.keyword.organic.organic.results.map((result, y) =>
                        result.link.includes('www.broadway.com') ? (
                          <div key={y} className="card my-2 p-2 bg-dark inline text-white d-inline fs-mini">
                              <p className="p-0 m-0 "><img src={result.favicon} width={16} height={16} className="mr-2" alt="thumbnail" />{result.title}</p>
                              <p className="p-0 m-0 fs-mini pl-4 text-muted">{result.link.replace('https://www.broadway.com','')}</p>
                          </div>
                        ) : (
                          <div key={y} className="card my-2 p-2 bg-light text-muted d-inline fs-mini">
                              <img src={result.favicon} width={16} height={16} className="mr-2" alt="thumbnail" /><a href={result.link} target="_blank" rel="noreferrer">{result.source}</a>
                          </div>
                        )
                      )}
                    </div>
                  ) : null}
                </Col>
                {props.keyword.organic.videos.exists ? (
                  <Col xs={12} sm={12} md={12} lg={4} xl={4} className="mb-2">
                    {!expand ? (
                      <div className="card p-4">
                        <h6 className="text-center pb-2">Videos</h6>
                        <h4 className="text-center d-none">
                          {props.keyword.organic.videos.placements.map((placement, x) =>
                            <span key={x}>
                                {formatNumber('X', placement, false)}
                                {x !== (props.keyword.organic.videos.placements.length-1) ? (
                                  <span>, </span>
                                ) : null}
                            </span>
                          )}
                        </h4>
                        {props.keyword.organic.videos.results.map((result, y) =>
                          <div key={y} className={result.channel.includes('Broadwaycom') || result.channel.includes('broadwaycom') ? 'bg-dark card p-2 mb-3':'card p-2 mb-3'}>
                            <div className="d-flex">
                              <div className="flex">
                                <img src={result.thumbnail} className="mr-2" alt="thumbnail" />
                              </div>
                              <div className="flex-grow">
                                <a href={result.link} target="_blank" rel="noreferrer" className={result.channel.includes('Broadwaycom') || result.channel.includes('broadwaycom') ? 'text-white fs-mini':'text-dark fs-mini'}><span className="fw-bold">{terse(result.channel,20)}</span><br/>{terse(result.platform,20)}</a>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}
                  </Col>
                ) : null}
                {props.keyword.data.top_stories ? (
                  <Col xs={12} sm={12} md={12} lg={4} xl={4} className="mb-2">
                    {!expand ? (
                      <div className="card p-4">
                        <h6 className="text-center pb-2">Top Stories</h6>
                        {props.keyword.data.top_stories.map((result, y) =>
                          <div key={y} className={result.link.includes('www.broadway.com') ? 'bg-dark card p-2 mb-3':'card p-2 mb-3'}>
                            <div className="d-flex">
                              <div className="flex">
                                <img src={result.source_logo} width={16} height={16} className="mr-2" alt="thumbnail" />
                              </div>
                              <div className="flex-grow">
                                <a href={result.link} target="_blank" rel="noreferrer" className={result.link.includes('www.broadway.com') ? 'text-white fs-mini':'text-dark fs-mini'}><span className="fw-bold">{terse(result.title,40)}</span><br/>{terse(result.source,40)}</a>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    ) : null}
                  </Col>
                ) : null}
                {props.keyword.organic.top_stories.exists ? (
                  <Col xs={12} sm={12} md={12} lg={4} xl={4} className="mb-4">
                    {!expand ? (
                      <div className="card p-4">
                        <h6 className="text-center text-muted pb-4">Top Stories</h6>
                          {props.keyword.organic.top_stories.results.map((story, x) =>
                            <p key={x} className="pb-0">
                              <a href={story.url} target="_blank" rel="noreferrer" className={story.domain.includes('www.broadway.com') ? 'text-black fw-bold':'text-muted'}>{story.domain.replace('www.','')}</a>
                            </p>
                          )}
                      </div>
                    ) : null}
                  </Col>
                ) : null}
                {props.keyword.organic.things_to_know.exists ? (
                  <Col xs={12} sm={2} md={2} lg={4} xl={4} className="mb-4">
                    {!expand ? (
                      <div className="card p-4">
                        <h6 className="text-center text-muted pb-4">Things to Know</h6>
                        {props.keyword.organic.things_to_know.exists ? (
                          props.keyword.organic.things_to_know.results.map((result, x) =>
                            <p key={x} className="pb-0">
                              <a href={result.url} target="_blank" rel="noreferrer" className={result.domain.includes('www.broadway.com') ? 'text-black fw-bold':'text-muted'}>{result.domain.replace('www.','')}</a>
                            </p>
                          )
                        ) : (
                          <h4>&nbsp;</h4>
                        )}
                      </div>
                    ) : null}
                  </Col>
                ) : null}
                {props.keyword.organic.people_also_ask.exists ? (
                  <Col xs={12} sm={2} md={2} lg={4} xl={4} className="mb-4">
                    {!expand ? (
                      <div className="card p-4">
                        <h6 className="text-center text-muted pb-4">People Also Ask</h6>
                        {props.keyword.organic.people_also_ask.exists ? (
                          props.keyword.organic.people_also_ask.results.map((result, x) =>
                            <p key={x} className="pb-0">
                              <a href={result.url} target="_blank" rel="noreferrer" className={result.domain.includes('www.broadway.com') ? 'text-black fw-bold':'text-muted'}>{result.domain.replace('www.','')}</a>
                            </p>
                          )
                        ) : (
                          <h4>&nbsp;</h4>
                        )}
                      </div>
                    ) : null}
                  </Col>
                ) : null}
              </Row>
            </div>
          </div>
      </div>

      <h6 className="d-none">
        <Button className="btn btn-default btn-xs inline-block text-inline" onClick={() => handleExpand(props.keyword.slug)}>
          <i className={'fa-light ' + (expand ? 'fa-minus' : 'fa-plus')} /> Show More Details
        </Button>
      </h6>

      {expand ? (
        <div className="w-100 bg-white p-0 m-0">
          <Row className="gutter-top-fixed-4">
            <Col xs={12} sm={12} md={12} lg={4} xl={4}>
              <h5 className="">Search Metrics</h5>
              <div className="gutter-top-fixed-1 bg-light border">
                <Table className="fs-mini p-0 m-0 border">
                  <tbody> 
                    {/* <tr>
                      <td className="p-0 m-0 pt-3 pl-3" width="50%">
                        <h6 className="p-0 m-0" style={{height: 30}}>Impressions</h6>
                      </td>
                      <td className="p-0 m-0 pt-3 pl-3 text-center" width="50%">
                        <h6 className="p-0 m-0">{formatNumber('X,', props.keyword.paid_daily.impressions, false)}</h6>
                      </td>
                    </tr>
                    <tr>
                      <td className="p-0 m-0 pt-3 pl-3" width="50%">
                        <h6 className="p-0 m-0" style={{height: 30}}>Visits</h6>
                      </td>
                      <td className="p-0 m-0 pt-3 pl-3 text-center" width="50%">
                        <h6 className="p-0 m-0">{formatNumber('X,', props.keyword.paid_daily.clicks, false)}</h6>
                      </td>
                    </tr>
                    <tr>
                      <td className="p-0 m-0 pt-3 pl-3" width="50%">
                        <h6 className="p-0 m-0" style={{height: 30}}>Orders</h6>
                      </td>
                      <td className="p-0 m-0 pt-3 pl-3 text-center" width="50%">
                        <h6 className="p-0 m-0">{formatNumber('X.X', props.keyword.paid_daily.orders, false)}</h6>
                      </td>
                    </tr>
                    <tr>
                      <td className="p-0 m-0 pt-3 pl-3" width="50%">
                        <h6 className="p-0 m-0" style={{height: 30}}>Conv %</h6>
                      </td>
                      <td className="p-0 m-0 pt-3 pl-3 text-center" width="50%">
                        <h6 className="p-0 m-0">{formatNumber('X.X', (parseFloat(props.keyword.paid_daily.orders/props.keyword.paid_daily.clicks)*100), false)}%</h6>
                      </td>
                    </tr> */}
                  </tbody>
                </Table>
              </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={4} xl={4}>
              <h5 className="">Funnel Metrics</h5>
              <div className="gutter-top-fixed-1 bg-light border">
              {/* <Table className="fs-mini nopad-bottom nomargin-bottom border">
                  <thead>
                    <tr>
                        <th width="33%">Metric</th>
                        <th className="text-center" width="33%">Value</th>
                        <th className="text-center" width="33%">PY +/-</th>
                    </tr>
                  </thead>
                  <tbody> 
                    <tr>
                      <td>
                        <h6 style={{height: 30}}>Starts</h6>
                      </td>
                      <td className="text-center">
                        <h6>{formatNumber('X,', props.keyword.years[0].clicks, false)}</h6>
                      </td>
                      <td className="text-center">
                        {props.keyword.years[1] ? (
                          <h6><Displays a={props.keyword.years[0].clicks} b={props.keyword.years[1].clicks} format="percent" /></h6>
                        ) : (
                          <span>--</span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h6 style={{height: 30}}>Conversion %</h6>
                      </td>
                      <td className="text-center">
                        <h6>{formatNumber('X.X%', props.keyword.years[0].conversion_percentage, false)}</h6>
                      </td>
                      <td className="text-center">
                        {props.keyword.years[1] ? (
                          <h6><Displays a={props.keyword.years[0].conversion_percentage} b={props.keyword.years[1].conversion_percentage} format="percent" /></h6>
                        ) : (
                          <span>--</span>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <h6 style={{height: 30}}>Orders</h6>
                      </td>
                      <td className="text-center">
                        <h6>{formatNumber('X,', props.keyword.years[0].orders, false)}</h6>
                      </td>
                      <td className="text-center">
                        {props.keyword.years[1] ? (
                          <h6><Displays a={props.keyword.years[0].orders} b={props.keyword.years[1].orders} format="percent" /></h6>
                        ) : (
                          <span>--</span>
                        )}
                      </td>
                    </tr>
                  </tbody>
              </Table> */}
              </div>
            </Col>
            <Col xs={12} sm={12} md={12} lg={4} xl={4}>
              <h5 className="">Revenue</h5>
              <div className="gutter-top-fixed-1 bg-light border">
    
              </div>
            </Col>
          </Row>
        </div>
      ) : null}

      {/* <hr/> */}

    </div>
  );
};

export default SemrushKeyword;