import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Displays from '../../../../components/Displays';

class GaPageDashboard extends React.Component {

  static propTypes = {
    page: PropTypes.object,
    print: PropTypes.bool
  };

  static defaultProps = {
    print: false
  };

  constructor(props) {
    super(props);

    this.state = {
        print: this.props.print,
        expand: false
    };
  }

  toggle = () => { 
    this.setState({
      expand: !this.state.expand
    });
  }

  render() {

    return (

        <div className="card p-2 bg-light h-100">

            <div className="m-0 p-0 overflow-hidden">
                <div className="p-0 overflow-hidden text-center">
                    {this.props.page.properties && this.props.page.properties.img ? (
                        <img src={this.props.page.properties.img} alt="thumbnail" className="d-inline overflow-hidden" height={175} />
                    ) : (
                        <p className="fs-mini text-muted">{this.props.page.path}</p>
                    )}
                </div>
            </div>
            <p className="pt-2 fs-mini text-muted text-center d-none">Landings</p>
            <h5 className="pt-3 text-center"><Displays a={parseInt(this.props.page.years[0].landings.total)} format="comma" /></h5>
        </div>
    
    );
  }

}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(GaPageDashboard));






