import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { DateTime } from 'luxon';

import {
  Row,
  Col,
  Button
} from 'reactstrap';

import Widget from '../../../components/Widget';
import Loading from '../../../components/Loading';
import { scurl } from '../../../core/utils.js';
import { filterUpdated } from '../../../core/utils';
import AdvertiserCard from './cards/Advertiser.js';

class WidgetAdvertisers extends React.Component {

  static propTypes = {
    print: PropTypes.bool,
  };

  static defaultProps = {
    print: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      display: 50,
    }

    this._isMounted = false;
    this.loadAdvertisers = this.loadAdvertisers.bind(this);
    this.viewAll = this.viewAll.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadAdvertisers();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if(filterUpdated(prevProps.filter, this.props.filter, 'ADVERTISERS WIDGET')) {
      this.loadAdvertisers();
    }
  }

  componentDidCatch(error, info) {
    if(this._isMounted) {
      this.setState({
        error: {
          didCatch: true,
          error: error,
          info: info,
          status: error.status || -99,
          message: error.message || 'Error occured'
        }
      });
    }
  }

  loadAdvertisers() {
    
    this.setState({
      isLoaded: false,
      results: []
    }, function() {

      var opts = {
        path: '/v2/adsales/advertisers',
        type: 'universal'
      };

      scurl(opts, function(err, results) {
        if(this._isMounted) {
          if(err) {
            this.setState({
              error: {
                json: err,
                status: err.status || -99,
                message: err.message || 'Error occured'
              }
            });
          } else {
            this.setState({
              isLoaded: true,
              results: results,
            });
          }
        } else {
          console.log('unmount handled');
        }
      }.bind(this));
    });  
  }

  viewAll = () => {    
    this.setState({
      display: 100
    });
  }

  render() {

    var title = <h5><span className="text-danger">Advertisers</span> {this.state.isLoaded ? (<small className="text-muted">From {DateTime.fromISO(this.state.results.dates[0].start).toLocaleString(DateTime.DATE_FULL)} to {DateTime.fromISO(this.state.results.dates[0].end).toLocaleString(DateTime.DATE_FULL)}.</small> ) : null}</h5>

    return (

      <div>

        <Widget title={title} className="widget-thin">

          <Loading loading={!this.state.isLoaded} error={this.state.error} pad={10} />

          {this.state.isLoaded ? (
          
            <div className="gutter-top-fixed-2">
              {this.props.print ? (
                  <Row>
                    {this.state.results.advertisers.map((advertiser, i) =>
                      <Col xs={12} sm={4} md={4} lg={4} xl={4} key={i} >
                        <AdvertiserCard advertiser={advertiser} print={true} />
                      </Col>
                    )}
                  </Row>
                ) : (
                  <div>
                    <Row>
                        {this.state.results.advertisers.map((advertiser, i) =>
                          i < this.state.display ? (
                            <Col xs={12} sm={12} md={12} lg={6} xl={4} key={i} >
                              <AdvertiserCard advertiser={advertiser} />
                            </Col>
                          ) : null
                        )}
                    </Row>
                    {this.state.display < this.state.results.advertisers.length ? (
                      <div className="text-center">
                        <Button className="btn-success" onClick={() => this.viewAll()}><i className="fa-light fa-arrows-up-down fa-lg gutter-right-fixed-1" />View All</Button>
                      </div>
                      ) : null}
                  </div>
                )}
            </div>

          ) : null}

        </Widget>

      </div>
      
    );
  }

}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(WidgetAdvertisers));
