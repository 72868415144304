import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Widget from '../../../components/Widget';
import Loading from '../../../components/Loading';
import PageCard from './cards/Page.js';
import PageDashboardCard from './cards/PageDashboard.js';

//import Displays from '../../../components/Displays';

import isScreen from '../../../core/screenHelper';

import { scurl } from '../../../core/utils.js';
import { filterUpdated, defaultTimespanV2, datespan, dateRangeDisplay, subSort, formatNumber } from '../../../core/utils';

import Slider from "react-slick";
import More from '../../../components/More';

import './styles.css';

import {
  Row,
  Col
} from 'reactstrap';

import Select from 'react-select';

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "#transparent" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "#transparent" }}
      onClick={onClick}
    />
  );
}

class WidgetGaSection extends React.Component {

  static propTypes = {
    print: PropTypes.bool,
    section: PropTypes.string,
    name: PropTypes.string,
    timespan: PropTypes.string,
    fixed_years: PropTypes.number,
    lookback: PropTypes.number,
    product_id: PropTypes.number,
    ga_filter: PropTypes.string,
    ga_property: PropTypes.string,
    dashboard: PropTypes.bool,
    tab: PropTypes.number
  };

  static defaultProps = {
    print: false,
    timespan: 'days',
    fixed_years: 1,
    ga_filter: '',
    ga_property: 'device.type',
    dashboard: false,
    tab: 1
  };

  constructor(props) {
    super(props);

    let dorw = defaultTimespanV2(this.props.filter);

    var page_count = 100;
    if(this.props.dashboard) {
      page_count = 20;
    }
    var page_cols = [12,12,12,12,12];
    if(!this.props.print) {
      if((isScreen('xs') || isScreen('sm'))) {
        page_count = 20
        page_cols = [12,12,12,12,12];
      } else {
        if(isScreen('md')) {
          page_count = 6
          page_cols = [4,4,6,4,4];
        }
      }
    }

    let section_items = [
      { value: 'all', label: 'All Pages' },
      { value: 'top', label: 'Top Level Pages' },
      { value: 'shows', label: 'Show Pages' },
      { value: 'venues', label: 'Venues' },
      { value: '----', label: '---------------' },
      { value: 'buzz,guides', label: 'Buzz+Guide Articles' },
      { value: 'buzz', label: 'Buzz Articles' },
      { value: 'guides', label: 'Guide Articles' },
      { value: 'stars', label: 'Star Files' },
    ];

    let section_selected = section_items[0];
    if(this.props.section) {
      for(var[i,item] of section_items.entries()) {
        if(item.value === this.props.section) {
          section_selected = section_items[i];
        }
      }
    }

    let sections = {
      items: section_items,
      selected: section_selected,
      isLoaded: true
    }

    if(this.props.dashboard) {
      //sections.selected = section_items[2]
    }

    let sortby_items = [
      { value: 'landings', label: 'Landings' },
      { value: 'views', label: 'Pageviews' },
      { value: 'purchases', label: 'Purchases' },
      { value: 'organic', label: 'Organic Landings' },
      { value: 'social', label: 'Social Landings' }
    ];
    let sortbys = {
      items: sortby_items,
      selected: sortby_items[0],
      isLoaded: true
    }

    this.state = {
      isLoaded: false,
      isError: false,
      chart_timespan: dorw,
      fixed_years: this.props.fixed_years,
      print: this.props.print,
      page_count: page_count,
      page_cols: page_cols,
      filter: {
        sections: sections,
        sortbys: sortbys
      },
    }

    this._isMounted = false;
    this.loadData = this.loadData.bind(this);
    this.filterPages = this.filterPages.bind(this);
    this.handleSectionChange = this.handleSectionChange.bind(this);
    this.handleSortbyChange = this.handleSortbyChange.bind(this);
    this.setDateRangeDisplay = this.setDateRangeDisplay.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if(filterUpdated(prevProps.filter, this.props.filter, 'GA WIDGET')) {
      this.loadData();
    } else {
      if(prevProps.ga_filter !== this.props.ga_filter) {
        this.loadData();
      }
    }
  }

  componentDidCatch(error, info) {
    if(this._isMounted) {
      this.setState({
        error: {
          didCatch: true,
          error: error,
          info: info,
          status: error.status || -99,
          message: error.message || 'Error occured'
        }
      });
    }
  }

  loadData() {

    this.setFilterText();
    this.setDateRangeDisplay();
    
    var lookback = datespan(this.props.filter);
    if(lookback < 7) {
      //lookback = 7;
    }
    this.setState({
      isLoaded: false,
      results: [],
      lookback: parseInt(lookback)
    }, function() {

      var opts = {
        path: '/v2/ga/pageviews',
        type: 'universal',
        
        years: 2
      };

      if(this.state.filter.sections.selected.value !== 'all') {
        opts.section = this.state.filter.sections.selected.value;
      }

      if(this.state.filter.sections.selected.value === 'buzz') {
        opts.years = 1;
      }

      if(this.props.product_id) {
        opts.product_id = this.props.product_id;
      }

      if(this.props.ga_filter.length > 0) {
        opts.ga_filter = this.props.ga_filter;
      }

      scurl(opts, function(err, results) {
        if(this._isMounted) {
          if(err) {
            this.setState({
              error: {
                json: err,
                status: err.status || -99,
                message: err.message || 'Error occured'
              }
            });
          } else {

            if(results) {
              this.setState({
                isLoaded: true,
                results: results,
                pages: results.pages
              }, function() {
                this.filterPages();
              });
            } else {
              this.setState({
                error: {
                  json: 'No data returned',
                  status: -99,
                  message: 'Error occured: No data returned'
                }
              });
            }
          }
        } else {
          console.log('handled unmount');
        }
      }.bind(this));
    });
  }

  changeChartTimespan = () => { 
    var change_display = this.state.chart_timespan;
    if(change_display === 'weeks') {
      change_display = 'days';
    } else {
      change_display = 'weeks';
    }
    this.setState({
      chart_timespan: change_display
    });
  }

  filterPages() {

    let filteredPages = [];
    for(var article of this.state.results.pages) {
      filteredPages.push(article);
    }

    filteredPages = subSort('sorts.' + this.state.filter.sortbys.selected.value,filteredPages,'desc');

    this.setState({
      ...this.state,
      pages: filteredPages
    });
  };

  setFilterText() {
    this.setState({
      ...this.state,
      filterText: this.props.skai_filter
    });
  };

  setDateRangeDisplay() {
    let drd = dateRangeDisplay(this.props.filter.dates, 'MMMM dd, yyyy');
    this.setState({
      ...this.state,
      dateRangeDisplay: drd
    });
  }

  handleSectionChange = selectedOption => {
    let url = '/app/content/' + this.props.tab + '/' + selectedOption.value;
    this.props.history.push(url);
  };

  handleSortbyChange = selectedOption => {

    this.setState({ 
      ...this.state,
      filter: {
        ...this.state.filter,
        sortbys: {
          ...this.state.filter.sortbys,
          selected: selectedOption
        }
        
      }
    }, function() {
      this.filterPages();
    });
  };

  render() {

    var slider_settings = {
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
      infinite: false,
      dots: false,
      slidesToShow: 12,
      slidesToScroll: 3,
      speed: 100,
      lazyLoad: true,
      responsive: [
        {
          breakpoint: 10000,
          settings: {
            slidesToShow: 6,
            slidesToScroll: 3
          }
        },
        {
          breakpoint: 1600,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 3
          }
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 2
          }
        }
      ]
    };

    var title1 = <h5><span className={this.props.dashboard ? '':'text-danger'}>{this.props.name}</span> <small className="text-muted">Site landings for {this.state.dateRangeDisplay ? (<span>{this.state.dateRangeDisplay}.&nbsp;</span>) : null}</small></h5>;

    return (
      this.props.dashboard ? (

        <Widget title={title1}>

          <Loading loading={!this.state.isLoaded} error={this.state.error} pad={10} />

          {this.state.isLoaded ? (
            <div className="gutter-left-fixed-2 gutter-right-fixed-2 gutter-bottom-fixed-2">
              <Slider {...slider_settings}>
                {this.state.pages.map((page, i) =>
                    i < this.state.page_count ? (
                      <PageDashboardCard key={i} print={this.props.print} page={page} />
                    ) : null
                  )}
              </Slider>
            </div>
          ) : null}

          <More url="/app/content/1/buzz" text="View All Articles" />
          
        </Widget>

      ) : (

        <div className="page-divide">

          <Row className="w-100 pl-4 mt-4 mb-5">
            <Col xs={10} sm={10} md={4} lg={4} xl={4} className="card p-3 bg-dark">
              <h6 className="text-muted text-white">ARTICLE TYPES</h6>  
              <Select
                value={this.state.filter.sections.selected}
                onChange={this.handleSectionChange}
                options={this.state.filter.sections.items}
                placeholder='Choose Section...'
              /> 
            </Col>
          </Row>

        
          <Widget title={title1} className="my-0 py-0">
            <Loading loading={!this.state.isLoaded} error={this.state.error} pad={10} />
            {this.state.isLoaded ? (
              <Row>
                  <Col xs={12} sm={2} md={2} lg={2} xl={2} className="gutter-top-fixed-2">
                      <div className="pt-2">
                          <p className="fs-mini text-center">Site<br/>Landings</p>
                          <h4 className="text-center">{formatNumber('X,', this.state.results.totals[0].landings.total, false)}</h4>
                          <p className="fs-mini text-muted text-center"></p>
                      </div>
                  </Col>
                  <Col xs={12} sm={2} md={2} lg={2} xl={2} className="gutter-top-fixed-2">
                      <div className="pt-2">
                          <p className="fs-mini text-center">Total<br/>Pageviews</p>
                          <h4 className="text-center">{formatNumber('X,', this.state.results.totals[0].views.total, false)}</h4>
                          <p className="fs-mini text-muted text-center"></p>
                      </div>
                  </Col>
                  <Col xs={12} sm={2} md={2} lg={2} xl={2} className="gutter-top-fixed-2">
                      <div className="pt-2">
                          <p className="fs-mini text-center">Landed &<br/>Purchased</p>
                          <h4 className="text-center">{formatNumber('X,', this.state.results.totals[0].purchases.total, false)}</h4>
                          <p className="fs-mini text-muted text-center"></p>
                      </div>
                  </Col>
                  {(this.state.filter.sections.selected.value !== 'buzz')&&(this.state.filter.sections.selected.value !== 'guides')&&(this.state.filter.sections.selected.value !== 'buzz,guides')&&(this.state.filter.sections.selected.value !== 'stars') ? (
                    <Col xs={12} sm={2} md={2} lg={2} xl={2} className="gutter-top-fixed-2">
                        <div className="pt-2">
                            <p className="fs-mini text-center">CPC<br/>Landings</p>
                            
                              <>
                                <h4 className="text-center">{formatNumber('X,', this.state.results.totals[0].landings.cpc, false)}</h4>
                                <p className="pt-2 m-0 text-center text-muted fs-mini">{formatNumber('X%', this.state.results.totals[0].landings.cpc/this.state.results.totals[0].landings.total, false)}</p>
                              </>
                        </div>
                    </Col>
                  ) : null }
                  <Col xs={12} sm={2} md={2} lg={2} xl={2} className="gutter-top-fixed-2">
                      <div className="pt-2">
                          <p className="fs-mini text-center">Organic<br/>Landings</p>
                          <h4 className="text-center m-0">{formatNumber('X,', this.state.results.totals[0].landings.organic, false)}</h4>
                          <p className="pt-2 m-0 text-center text-muted fs-mini">{formatNumber('X%', this.state.results.totals[0].landings.organic/this.state.results.totals[0].landings.total, false)}</p>
                      </div>
                  </Col>
                  <Col xs={12} sm={2} md={2} lg={2} xl={2} className="gutter-top-fixed-2">
                      <div className="pt-2">
                          <p className="fs-mini text-center">Social<br/>Landings</p>
                          <h4 className="text-center m-0">{formatNumber('X,', this.state.results.totals[0].landings.social, false)}</h4>
                          <p className="pt-2 m-0 text-center text-muted fs-mini">{formatNumber('X%', this.state.results.totals[0].landings.social/this.state.results.totals[0].landings.total, false)}</p>
                      </div>
                  </Col>
                  {(this.state.filter.sections.selected.value === 'buzz')||(this.state.filter.sections.selected.value === 'guides')||(this.state.filter.sections.selected.value === 'buzz,guides')||(this.state.filter.sections.selected.value === 'stars') ? (
                    <Col xs={12} sm={2} md={2} lg={2} xl={2} className="gutter-top-fixed-2">
                        <div className="pt-2">
                            <p className="fs-mini text-center">Direct, Referral<br/>& Other</p>
                            <h4 className="text-center m-0">{formatNumber('X,', this.state.results.totals[0].landings.total-(this.state.results.totals[0].landings.search+this.state.results.totals[0].landings.social+this.state.results.totals[0].landings.email+this.state.results.totals[0].landings.google_news), false)}</h4>
                            <p className="pt-2 m-0 text-center text-muted fs-mini">{formatNumber('X%', (this.state.results.totals[0].landings.total-(this.state.results.totals[0].landings.search+this.state.results.totals[0].landings.social+this.state.results.totals[0].landings.email+this.state.results.totals[0].landings.google_news))/this.state.results.totals[0].landings.total, false)}</p>
                        </div>
                    </Col>
                  ) : null }
              </Row>
            ) : null}
          </Widget>
        

        
          <Widget title={
            <Row className="mt-0 mb-2">
              <Col xs={2} sm={4} md={6} lg={8} xl={10}>
                <h5 className="text-danger">Traffic By Page</h5>
              </Col>
              <Col xs={10} sm={8} md={6} lg={4} xl={2}>
                <div className="p-1">
                  <h6 className="mb-1 fw-bold">SORT BY</h6>  
                  <Select
                    value={this.state.filter.sortbys.selected}
                    onChange={this.handleSortbyChange}
                    options={this.state.filter.sortbys.items}
                    placeholder='Sortby...'
                  /> 
                </div>
              </Col>
            </Row>
          } className="mt-5 pt-0 pr-0 mr-0">
            <Loading loading={!this.state.isLoaded} error={this.state.error} pad={10} />
            {this.state.isLoaded ? (
              <>
                

                <div className="w-100">
                  {this.state.pages.map((page, i) =>
                    i < this.state.page_count ? (
                      <PageCard key={i} print={this.props.print} page={page} />
                    ) : null
                  )}
                </div>
              </>
            ) : null}
          </Widget>
      

        </div>
      )
      
    );
  }

}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(WidgetGaSection));
