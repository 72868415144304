import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  Row, 
  Col
} from 'reactstrap';

import Widget from '../../../components/Widget';
import Loading from '../../../components/Loading';

import Select from 'react-select';

import { scurl, checkExists } from '../../../core/utils.js';
import { filterUpdated, defaultTimespanV2, datespan, dateRangeDisplay, displayTrafficMetric } from '../../../core/utils';

import UBar from '../../universal/Bar';

import DashCam from '../../universal/DashCam/DashCam.js';

class WidgetGaSections extends React.Component {

  static propTypes = {
    print: PropTypes.bool,
    dashboard: PropTypes.bool,
    timespan: PropTypes.string,
    fixed_years: PropTypes.number,
    refunds: PropTypes.bool,
    limit_sources: PropTypes.string,
    product_id: PropTypes.number,
    traffic_metric: PropTypes.string,
    user: PropTypes.object
  };

  static defaultProps = {
    print: false,
    dashboard: false,
    timespan: 'days',
    fixed_years: 3,
    traffic_metric: 'landings'
  };

  constructor(props) {
    super(props);

    let dorw = defaultTimespanV2(this.props.filter);
    let drd = dateRangeDisplay(this.props.filter.dates, 'MMMM dd, yyyy');
    
    let col_lg = 12;
    if(this.props.dashboard) {
      col_lg = 12;
    }

    let metric_options = [
      { value: 0, label: 'Landings' },
      { value: 1, label: 'Pageviews' },
      { value: 2, label: 'Purchases' },
    ]

    let metric_selected = metric_options[0];

    let fixed_years = this.props.fixed_years;
    if(checkExists(this.props.user.permissions.fixed_years)) {
      fixed_years = this.props.user.permissions.fixed_years;
    }

    this.state = {
      isLoaded: false,
      isError: false,
      perf_date: this.props.perf_date,
      chart_timespan: dorw,
      fixed_years: fixed_years,
      print: this.props.print,
      dateRangeDisplay: drd,
      col_lg: col_lg,
      traffic_metric: this.props.traffic_metric,
      format: 'XM',
      metric_options: metric_options,
      metric_selected: metric_selected
    }

    this._isMounted = false;
    this.loadData = this.loadData.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if(filterUpdated(prevProps.filter, this.props.filter, 'SALES TIMESPANS WIDGET')) {
      this.setDateRange();
      this.loadData();
    } else {
      if(prevProps.skai_filter !== this.props.skai_filter) {
        this.loadData();
      }
    }
  }

  componentDidCatch(error, info) {
    if(this._isMounted) {
      this.setState({
        error: {
          didCatch: true,
          error: error,
          info: info,
          status: error.status || -99,
          message: error.message || 'Error occured'
        }
      });
    }
  }

  loadData() {

    var lookback = datespan(this.props.filter);
    if(lookback < 7) {
      //lookback = 7;
    }
    this.setState({
      isLoaded: false,
      results: [],
      lookback: parseInt(lookback)
    }, function() {

      var opts = {
        path: '/v2/ga/sections',
        type: 'universal',
        years: this.state.fixed_years
      };

      if(this.props.product_id) {
        opts.product_id = this.props.product_id;
      }

      scurl(opts, function(err, results) {
        if(this._isMounted) {
          if(err) {
            this.setState({
              error: {
                json: err,
                status: err.status || -99,
                message: err.message || 'Error occured'
              }
            });
          } else {

            if(results) {
              this.setState({
                isLoaded: true,
                results: results,
              }, function() {
                this.setDashCam();
              });
            } else {
              this.setState({
                error: {
                  json: 'No data returned',
                  status: -99,
                  message: 'Error occured: No data returned'
                }
              });
            }
          }
        } else {
          console.log('handled unmount');
        }
      }.bind(this));
    });
  }

  changeChartTimespan = () => { 
    var change_display = this.state.chart_timespan;
    if(change_display === 'weeks') {
      change_display = 'days';
    } else {
      change_display = 'weeks';
    }
    this.setState({
      chart_timespan: change_display
    });
  }

  changeMetric = (selected) => { 
    
    let metric = this.state.traffic_metric;
    switch(selected.value) {
      case 0:
        metric = 'landings';
        break;
      case 1:
        metric = 'views';
        break;
      case 2:
        metric = 'purchases';
        break;
      default:
        break;
    }

    this.setState({
      traffic_metric: metric,
      metric_selected: selected,
    }, function() {
      this.setDashCam();
    });
  };

  changeTrafficMetric = () => { 
    // var change_display = '';
    // if(this.props.affiliate) {
    //   change_display = changeAffiliateSalesMetric(this.state.sales_metric);
    // } else {
    //   change_display = changeSalesMetric(this.state.sales_metric);
    // }

    // let format = 'X';
    // console.log(change_display);
    // switch(change_display) {
    //   case 'orders':
    //     format = 'X,';
    //     break;
    //   case 'qty':
    //     format = 'XM';
    //     break;
    //   case 'face':
    //     format = '$XM';
    //     break;
    //   case 'fees':
    //     format = '$XM';
    //     break;
    //   case 'net_net':
    //     format = '$XM';
    //     break;
    //   case 'access_fees':
    //     format = '$XM';
    //     break;
    //   case 'affiliate_commission':
    //     format = '$XM';
    //     break;
    //   default:
    //     break;
    // }

    // this.setState({
    //   sales_metric: change_display,
    //   format: format
    // }, function() {
    //   this.setDashCam()
    // });
  };
  
  setDashCam() {

    let headlineFormat = 'X,';
    if(this.state.traffic_metric === 'landings') {
      headlineFormat = 'X,';
    }

    let panels = [];
    for(var section of this.state.results.sections) {
      let panel = {
        title: section.name,
        value: section.years[0][this.state.traffic_metric].total,
        format: 'X,',
        yoy: parseFloat((section.years[0][this.state.traffic_metric].total-section.years[1][this.state.traffic_metric].total)/section.years[1][this.state.traffic_metric].total)
      }
      panels.push(panel);
    }

    let dashCamData = {
      results: this.state.results,
      headline: {
        title: displayTrafficMetric(this.state.traffic_metric),
        value: this.state.results.totals[0][this.state.traffic_metric].total,
        format: headlineFormat,
        yoy: parseFloat((this.state.results.totals[0][this.state.traffic_metric].total-this.state.results.totals[1][this.state.traffic_metric].total)/this.state.results.totals[1][this.state.traffic_metric].total)
      },
      panels: panels
    };

    this.setState({
      ...this.state,
      dashCamData: dashCamData
    });
  };

  setDateRange() {
    let drd = dateRangeDisplay(this.props.filter.dates, 'MMMM dd, yyyy');
    let dorw = defaultTimespanV2(this.props.filter);

    this.setState({
      ...this.state,
      dateRangeDisplay: drd,
      chart_timespan: dorw
    });
  }

  render() {

    let changePT = (event) => { 
      this.changeMetric(event);
    };

    return (

      this.props.dashboard ? (

        <Widget
          className="mb-0 h-100"
          bodyClass={`mt p-4`}
          title={
            <div className="d-flex justify-content-between flex-wrap">
              <h5 className="pt-1"><span>Site {displayTrafficMetric(this.state.traffic_metric)}</span><small className="text-muted pl-2">by section vs prior year</small></h5>
              {this.state ? (
                <Select
                  value={this.state.metric_selected}
                  onChange={(event) => this.changeMetric(event) }
                  options={this.state.metric_options}
                  placeholder='Filter results...'
                  isMulti={false}
                  className="form-control-sm p-0 m-0"
                /> 
              ) : null}
            </div>
          }
        >

          <Loading loading={!this.state.isLoaded} error={this.state.error} pad={10} className="pl-4" />

          {this.state.isLoaded ? (
            <Row>
              <Col xs={12} md={12} lg={12} xl={12} className="pb-3">
                <DashCam 
                  isLoaded={this.state.isLoaded} 
                  print={this.props.print} 
                  results={this.state.results} 
                  lookback={this.state.lookback} 
                  payload={this.state.dashCamData}
                />
              </Col>
              <Col xs={12} md={12} lg={12} xl={12} className="">
                <UBar
                  data={this.state.results}
                  print={this.props.print}
                  labels={false}
                  grid={false}
                  parent={this.state.traffic_metric}
                  field="total"
                  format={this.state.format}
                  chart_aspect_width={3}
                  chart_aspect_height={1}
                />
                <small className="text-muted pt-1">Total {displayTrafficMetric(this.state.traffic_metric)} vs prior two years</small>
              </Col>
            </Row>
          ) : null}

          {/* <More className="gutter-right-fixed-2" url="/app/transactions" tab={0} perf_date={this.state.perf_date} /> */}
          
        </Widget>

      ) : (
        <Widget title={
          <div className="d-flex justify-content-between flex-wrap">
            <h5><span className="text-danger">Sales KPIs</span> <small className="text-muted">{this.state.dateRangeDisplay ? (<span>from {this.state.dateRangeDisplay} .&nbsp;</span>) : null}Charts are displayed by <button className="header-link" onClick={() => this.changeChartTimespan()}>{this.state.chart_timespan === 'days' ? ('day') : ('week')}</button>.</small></h5>
            {this.state ? (
              <Select
                value={this.state.perf_trans_selected}
                onChange={(event) => changePT(event) }
                options={this.state.perf_trans_options}
                placeholder='Filter results...'
                isMulti={false}
                className="form-control-sm"
              /> 
            ) : null}
          </div>
        }>

          <Loading loading={!this.state.isLoaded} error={this.state.error} pad={10} />

          {this.state.isLoaded ? (
            <>
{/* 
              <ChartLegend print={this.props.print} years={this.state.fixed_years} className="gutter-top-fixed-4" lookback={this.state.lookback} />

              <Row className="gutter-top-fixed-2 gutter-bottom-fixed-2">
                <DataPointCard print={this.props.print} results={this.state.results} title="QTY" field="qty" lookback={this.state.lookback} format="X," timespan={this.state.chart_timespan} lg={this.state.col_lg} />
                <DataPointCard print={this.props.print} results={this.state.results} title="Face Value" field="face" lookback={this.state.lookback} format="$X" timespan={this.state.chart_timespan} lg={this.state.col_lg} />
                <DataPointCard print={this.props.print} results={this.state.results} title="Service Fees" field="fees" lookback={this.state.lookback} format="$X" timespan={this.state.chart_timespan} lg={this.state.col_lg} />
                <DataPointCard print={this.props.print} results={this.state.results} title="Avg Price" lookback={this.state.lookback} format="$X.XX" field="average_price" timespan={this.state.chart_timespan} lg={this.state.col_lg} />
                <DataPointCard print={this.props.print} results={this.state.results} title="Margin" lookback={this.state.lookback} format="X.X%" field="margin" timespan={this.state.chart_timespan} lg={this.state.col_lg} />
                <DataPointCard print={this.props.print} results={this.state.results} title="Avg Fixed Cost" parent="per_ticket" field="fixed_cost" lookback={this.state.lookback} format="$X.XX" timespan={this.state.chart_timespan} lg={this.state.col_lg} />
                <DataPointCard print={this.props.print} results={this.state.results} title="Avg Order Break-Even" parent="per_order" field="net" lookback={this.state.lookback} format="$X.XX" timespan={this.state.chart_timespan} lg={this.state.col_lg} />

                {this.props.user && this.props.user.admin ? (
                  <DataPointCard print={this.props.print} results={this.state.results} title="Avg Access Fee" field="access_fees_per_ticket" lookback={this.state.lookback} format="$X.XX" timespan={this.state.chart_timespan} lg={this.state.col_lg} />
                ): null}
                {this.props.user && this.props.user.admin ? (
                  <DataPointCard print={this.props.print} results={this.state.results} title="Affiliate Fees" field="affiliate_commission" lookback={this.state.lookback} format="$X" timespan={this.state.chart_timespan} lg={this.state.col_lg} />
                ): null}

              </Row> */}

            </>
          ) : null}
        </Widget>
      )
    );
  }

}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(WidgetGaSections));
