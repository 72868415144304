import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { formatNumber, terse } from '../../../../core/utils';
import Poster from '../../../../components/Poster';

import { Table } from 'reactstrap';

class WidgetKnownProductCard extends React.Component {

  static propTypes = {
    advertiser: PropTypes.object,
    print: PropTypes.bool
  };

  static defaultProps = {
    print: false
  };

  constructor(props) {
    super(props);

    this.selectProduct = this.selectProduct.bind(this);
  }

  selectProduct = (e, id) => {
    e.preventDefault();
    this.props.history.push('/app/products/' + id);
  }

  render() {

    return (

      <div>

        <div className="card gutter-bottom-fixed-4">
          <div className="card-body nopad nomargin">

            
              <div className="d-flex">
                {this.props.advertiser.show ? (
                  <div>
                    <a href="/" onClick={((e) => this.selectProduct(e, this.props.advertiser.show.id))}>
                      <Poster id={this.props.advertiser.show.poster_id} w={95} className="border-right img-poster" style={{width: 105}} />
                    </a>
                  </div>
                ) : null }
                <div className="flex-grow">
                    <Table className="fs-mini bordered nopad-bottom nomargin-bottom border p-0 m-0 bg-light">
                      <tbody className="p-1"> 
                        <tr>
                          <td className="" colSpan={2}>
                            {this.props.advertiser.show ? (
                              <a href="/" onClick={((e) => this.selectProduct(e, this.props.advertiser.show.id))} className="fw-bold">
                                {this.props.advertiser.show.short_name ? (
                                  <span>{this.props.advertiser.show.short_name}</span>
                                ) : (
                                  <span>{terse(this.props.advertiser.show.name)}</span>
                                )}
                              </a>
                            ) : (
                              <span className="fw-bold">{terse(this.props.advertiser.advertiser)}</span>
                            )}
                          </td>
                          <td width="30%">
                            <span className="fw-bold">Spend</span>
                          </td>
                          <td width="20%">
                            <span className="fw-bold">{formatNumber('$X', this.props.advertiser.spend, false)}</span>
                          </td>
                        </tr>
                        <tr>
                          <td width="30%">
                            <span>Weeks</span>
                          </td>
                          <td width="20%">
                            <span>{formatNumber('X,', this.props.advertiser.weeks, false)}</span>
                          </td>
                          <td width="30%">
                            <span>Average Weekly</span>
                          </td>
                          <td width="20%">
                            <span>{formatNumber('$X', (this.props.advertiser.spend/this.props.advertiser.weeks), false)}</span>
                          </td>
                        </tr>
                        {this.props.advertiser.sales && this.props.advertiser.poop ? (
                          <tr>
                            <td width="30%">
                                <span>Face Value</span>
                            </td>
                            <td width="20%">
                                <span>{formatNumber('$X', this.props.advertiser.sales.face, false)}</span>
                            </td>
                            <td width="30%">
                                <span>ROAS</span>
                            </td>
                            <td width="20%">
                                <span>{formatNumber('X,', (this.props.advertiser.sales.face/this.props.advertiser.spend), false)} : 1</span>
                            </td>
                          </tr>
                        ) : null}
                        {/* <tr>
                          <td>
                            <span>Average Ticket Price</span>
                          </td>
                          <td>
                            <span>{formatNumber('$X.XX', this.props.product.average_price, false)}</span>
                          </td>
                          <td>
                            <span className="fw-bold">Net Profit</span>
                          </td>
                          <td className="">
                            <span className="fw-bold">{formatNumber('$X', this.props.product.net_net, false)}</span>
                          </td>
                        </tr> */}
                      </tbody>
                    </Table>
                    {/* <div>
                      <h5>{formatNumber('$X.XX', this.props.product.per_order.net, false)}</h5>
                      <p className="m-0 p-0 gutter-top-fixed-1 text-muted">Margin: {formatNumber('X.X%', (1-(this.props.product.per_order.fixed_cost/this.props.product.per_order.fees)), false)}</p>
                    </div> */}
                </div>
              </div>


          </div>
        </div>
                          
      </div>
      
    );
  }

}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(WidgetKnownProductCard));
