import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Displays from '../../../../components/Displays';

import { Row, Col, Table } from 'reactstrap';

import { formatNumber, terse } from '../../../../core/utils';

class GaPage extends React.Component {

  static propTypes = {
    page: PropTypes.object,
    print: PropTypes.bool
  };

  static defaultProps = {
    print: false
  };

  constructor(props) {
    super(props);

    this.state = {
        print: this.props.print,
        expand: false
    };
  }

  toggle = () => { 
    this.setState({
      expand: !this.state.expand
    });
  }

  render() {

    return (

        <div className="gutter-bottom-fixed-3 card p-4 pt-0 bg-light">



            <Row>
                <Col xs={12} sm={6} md={6} lg={6} xl={6} className="">
                    {this.props.page.properties && this.props.page.properties.title ? (
                        <>
                            <p className="p-0 m-0">{terse(this.props.page.properties.title,100)}</p>
                            {/* <p className="p-0 m-0 text-wrap"><span className="text-muted fs-mini">{this.props.page.path}</span></p> */}
                        </>
                    ) : (
                        <p className="p-0 m-0">{this.props.page.path}</p>
                    )}
                </Col>
                <Col xs={12} sm={6} md={6} lg={6} xl={6} className="text-right">
                    <p className="p-0 m-0">Total Pageviews: {formatNumber('X,', this.props.page.years[0].views.total, false)}</p>
                </Col>
            </Row>

            <div className="d-flex flex-row m-0 p-0 mt-3">
                <div className="p-0 text-wrap">
                    {this.props.page.properties && this.props.page.properties.img ? (
                        this.props.page.path.includes('/shows/') ? (
                            <img src={this.props.page.properties.img} alt="thumbnail" className="img-fluid " width={100} />
                        ) : (
                            <img src={this.props.page.properties.img} alt="thumbnail" className="img-fluid " width={200} />
                        )   
                    ) : (
                        <img src={`https://m.media-amazon.com/images/I/51T29DgDnTL.png`} alt="thumbnail" className="img-fluid " height={100} width={100} />
                    )}
                    
                    {this.props.page.properties && (this.props.page.properties.pub_date > -1) ? (
                        <p className=""><span className="fw-bold fs-mini pr-1">Published:</span> {this.props.page.properties.pub_date}<small className="pl-4 text-muted">{this.props.page.properties.age} days ago</small></p>
                    ) : null }    
                </div>
                <div className="flex-fill pl-4" style={{'height': '100%'}}>
                    <Row>
                        <Col xs={12} sm={4} md={4} lg={4} xl={4} className="">
                            <h6 className="text-muted d-none">Site Landings by Source</h6>
                            <Table className="fs-mini bordered nopad-bottom nomargin-bottom border p-0 m-0 bg-white">
                                <tbody className="p-1"> 
                                <tr>
                                    <td colSpan={2}>
                                        <h6 className="fw-bold p-0 m-0">Site Landings</h6>
                                    </td>
                                    <td colSpan={2}>
                                        <h6 className="fw-bold p-0 m-0">{formatNumber('X,', this.props.page.years[0].landings.total, false)}</h6>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="30%">
                                        {this.props.page.path.includes('buzz')||this.props.page.path.includes('guide/')  ? (
                                            <span>Google News</span>
                                        ) : (
                                            <span>Paid Search</span>
                                        )}
                                    </td>
                                    <td width="20%">
                                        {this.props.page.path.includes('buzz')||this.props.page.path.includes('guide/')  ? (
                                            <>
                                                <p className="p-0 m-0 fw-bold">{formatNumber('X,', this.props.page.years[0].landings.google_news, false)}</p>
                                                <p className="p-0 m-0 text-left text-muted fs-mini">{formatNumber('X%', this.props.page.years[0].landings.google_news/this.props.page.years[0].landings.total, false)}</p>
                                            </>
                                        ) : (
                                            <>
                                                <p className="p-0 m-0 fw-bold">{formatNumber('X,', this.props.page.years[0].landings.cpc, false)}</p>
                                                <p className="p-0 m-0 text-left text-muted fs-mini">{formatNumber('X%', this.props.page.years[0].landings.cpc/this.props.page.years[0].landings.total, true)}</p>
                                            </>
                                        )}
                                    </td>
                                    <td width="30%">
                                        <span>Organic Search</span>
                                    </td>
                                    <td width="20%">
                                        <p className="p-0 m-0 fw-bold">{formatNumber('X,', this.props.page.years[0].landings.organic, false)}</p>
                                        <p className="p-0 m-0 text-left text-muted fs-mini">{formatNumber('X%', this.props.page.years[0].landings.organic/this.props.page.years[0].landings.total, true)}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span>Social</span>
                                    </td>
                                    <td>
                                        <p className="p-0 m-0 fw-bold">{formatNumber('X,', this.props.page.years[0].landings.social, false)}</p>
                                        <p className="p-0 m-0 text-left text-muted fs-mini">{formatNumber('X%', this.props.page.years[0].landings.social/this.props.page.years[0].landings.total, true)}</p>
                                    </td>
                                    <td>
                                        <span>Email</span>
                                    </td>
                                    <td>
                                        <p className="p-0 m-0 fw-bold">{formatNumber('X,', this.props.page.years[0].landings.email, false)}</p>
                                        <p className="p-0 m-0 text-left text-muted fs-mini">{formatNumber('X%', this.props.page.years[0].landings.email/this.props.page.years[0].landings.total, true)}</p>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span>Direct, Referral & Other</span>
                                    </td>
                                    <td>
                                        <p className="p-0 m-0 fw-bold">{formatNumber('X,', this.props.page.years[0].landings.total-(this.props.page.years[0].landings.search+this.props.page.years[0].landings.social+this.props.page.years[0].landings.email+this.props.page.years[0].landings.google_news), false)}</p>
                                        <p className="p-0 m-0 text-left text-muted fs-mini">{formatNumber('X%', (this.props.page.years[0].landings.total-(this.props.page.years[0].landings.search+this.props.page.years[0].landings.social+this.props.page.years[0].landings.email+this.props.page.years[0].landings.google_news))/this.props.page.years[0].landings.total, true)}</p>
                                    </td>
                                    <td>
                                        <span>&nbsp;</span>
                                    </td>
                                    <td>
                                        {/* <p className="p-0 m-0">{formatNumber('X,', this.props.page.years[0].landings.email, false)}</p>
                                        <p className="p-0 m-0 text-left text-muted fs-mini">{formatNumber('X%', this.props.page.years[0].landings.email/this.props.page.years[0].landings.total, false)}</p> */}
                                    </td>
                                </tr>
                                </tbody>
                            </Table>
                        </Col>
                        <Col xs={12} sm={4} md={4} lg={4} xl={4} className="">
                            <Table className="fs-mini bordered nopad-bottom nomargin-bottom border p-0 m-0 bg-white">
                                <tbody className="p-1"> 
                                    <tr>
                                        <td colSpan={2}>
                                            <h6 className="fw-bold p-0 m-0">Social</h6>
                                        </td>
                                        <td colSpan={2}>
                                            <h6 className="fw-bold p-0 m-0">{formatNumber('X,', this.props.page.years[0].landings.social, false)}</h6>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="30%">
                                            <span>Facebook</span>
                                        </td>
                                        <td width="20%">
                                            <p className="p-0 m-0 fw-bold">{formatNumber('X,', this.props.page.years[0].landings.facebook, false)}</p>
                                            <p className="p-0 m-0 text-left text-muted fs-mini">{formatNumber('X%', this.props.page.years[0].landings.facebook/this.props.page.years[0].landings.social, true)}</p>
                                        </td>
                                        <td width="30%">
                                            <span>Instagram</span>
                                        </td>
                                        <td width="20%">
                                            <p className="p-0 m-0 fw-bold">{formatNumber('X,', this.props.page.years[0].landings.instagram, false)}</p>
                                            <p className="p-0 m-0 text-left text-muted fs-mini">{formatNumber('X%', this.props.page.years[0].landings.instagram/this.props.page.years[0].landings.social, true)}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="30%">
                                            <span>Twitter</span>
                                        </td>
                                        <td width="20%">
                                            <p className="p-0 m-0 fw-bold">{formatNumber('X,', this.props.page.years[0].landings.twitter, false)}</p>
                                            <p className="p-0 m-0 text-left text-muted fs-mini">{formatNumber('X%', this.props.page.years[0].landings.twitter/this.props.page.years[0].landings.social, true)}</p>
                                        </td>
                                        <td width="30%">
                                            <span>Threads</span>
                                        </td>
                                        <td width="20%">
                                            <p className="p-0 m-0 fw-bold">{formatNumber('X,', this.props.page.years[0].landings.threads, false)}</p>
                                            <p className="p-0 m-0 text-left text-muted fs-mini">{formatNumber('X%', this.props.page.years[0].landings.threads/this.props.page.years[0].landings.social, true)}</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                        <Col xs={12} sm={4} md={4} lg={4} xl={4} className="">
                            <h6 className="text-muted d-none">Site Landings by Source</h6>
                            <Table className="fs-mini bordered nopad-bottom nomargin-bottom border p-0 m-0 bg-white">
                                <tbody className="p-1"> 
                                    <tr>
                                        <td colSpan={2}>
                                            <h6 className="fw-bold p-0 m-0">Purchases</h6>
                                        </td>
                                        <td colSpan={2}>
                                            <h6 className="fw-bold p-0 m-0">{formatNumber('X,', this.props.page.years[0].purchases.total, false)}</h6>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="30%">
                                            <span>Paid</span>
                                        </td>
                                        <td width="20%">
                                            <p className="p-0 m-0 fw-bold">{formatNumber('X,', this.props.page.years[0].purchases.cpc, false)}</p>
                                            <p className="p-0 m-0 text-left text-muted fs-mini">{formatNumber('X%', this.props.page.years[0].purchases.cpc/this.props.page.years[0].purchases.total, true)}</p>
                                        </td>
                                        <td width="30%">
                                            <span>Organic</span>
                                        </td>
                                        <td width="20%">
                                            <p className="p-0 m-0 fw-bold">{formatNumber('X,', this.props.page.years[0].purchases.organic, false)}</p>
                                            <p className="p-0 m-0 text-left text-muted fs-mini">{formatNumber('X%', this.props.page.years[0].purchases.organic/this.props.page.years[0].purchases.total, true)}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="30%">
                                            <span>Email</span>
                                        </td>
                                        <td width="20%">
                                            <p className="p-0 m-0 fw-bold">{formatNumber('X,', this.props.page.years[0].purchases.email, false)}</p>
                                            <p className="p-0 m-0 text-left text-muted fs-mini">{formatNumber('X%', this.props.page.years[0].purchases.email/this.props.page.years[0].purchases.total, true)}</p>
                                        </td>
                                        <td width="30%">
                                            <span>Direct, Referral & Other</span>
                                        </td>
                                        <td width="20%">
                                            <p className="p-0 m-0 fw-bold">{formatNumber('X,', this.props.page.years[0].purchases.total-(this.props.page.years[0].purchases.search+this.props.page.years[0].purchases.email), false)}</p>
                                            <p className="p-0 m-0 text-left text-muted fs-mini">{formatNumber('X%', (this.props.page.years[0].purchases.total-(this.props.page.years[0].purchases.search+this.props.page.years[0].purchases.email))/this.props.page.years[0].purchases.total, true)}</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </div>
            </div>

            <div className="bg-white p-0 m-0 border mt-3 d-none">
                <h6 className="mt-3 pl-3 text-muted">Site Landings by Source</h6>
                <Row>
                    {!this.props.page.path.includes('buzz')&&!this.props.page.path.includes('guide/')  ? (
                    <Col xs={12} sm={2} md={2} lg={2} xl={2} className="py-3">
                        <p className="fs-mini text-muted text-center pt-2"><span className="fw-bold">Search</span><br/>Paid</p>
                        <h5 className="text-center m-0 p-0"><Displays a={this.props.page.years[0].landings.cpc} format="comma" /></h5>
                        <p className="pt-2 m-0 text-center text-muted fs-mini">{formatNumber('X%', this.props.page.years[0].landings.cpc/this.props.page.years[0].landings.total, false)}</p>
                    </Col>
                    ) : null}
                    <Col xs={12} sm={2} md={2} lg={2} xl={2} className="py-3">
                        <p className="fs-mini text-muted text-center pt-2"><span className="fw-bold">Search</span><br/>Organic</p>
                        <h5 className="text-center m-0 p-0"><Displays a={this.props.page.years[0].landings.organic} format="comma" /></h5>
                        <p className="pt-2 m-0 text-center text-muted fs-mini">{formatNumber('X%', this.props.page.years[0].landings.organic/this.props.page.years[0].landings.total, false)}</p>
                    </Col>
                    {this.props.page.path.includes('buzz')||this.props.page.path.includes('guide/')  ? (
                    <Col xs={12} sm={2} md={2} lg={2} xl={2} className="py-3">
                        <p className="fs-mini text-muted text-center pt-2"><span className="fw-bold">Google</span><br/>News</p>
                        <h5 className="text-center m-0 p-0"><Displays a={this.props.page.years[0].landings.google_news} format="comma" /></h5>
                        <p className="pt-2 m-0 text-center text-muted fs-mini">{formatNumber('X%', this.props.page.years[0].landings.google_news/this.props.page.years[0].landings.total, false)}</p>
                    </Col>
                    ) : null}
                    <Col xs={12} sm={2} md={2} lg={2} xl={2} className="py-3">
                        <p className="fs-mini text-muted text-center pt-2"><span className="fw-bold">Social</span><br/>Facebook</p>
                        <h5 className="text-center m-0 p-0"><Displays a={this.props.page.years[0].landings.facebook} format="comma" /></h5>
                        <p className="pt-2 m-0 text-center text-muted fs-mini">{formatNumber('X%', this.props.page.years[0].landings.facebook/this.props.page.years[0].landings.total, false)}</p>
                    </Col>
                    <Col xs={12} sm={2} md={2} lg={2} xl={2} className="py-3">
                        <p className="fs-mini text-muted text-center pt-2"><span className="fw-bold">Social</span><br/>Twitter</p>
                        <h5 className="text-center m-0 p-0"><Displays a={this.props.page.years[0].landings.twitter} format="comma" /></h5>
                        <p className="pt-2 m-0 text-center text-muted fs-mini">{formatNumber('X%', this.props.page.years[0].landings.twitter/this.props.page.years[0].landings.total, false)}</p>
                    </Col>
                    <Col xs={12} sm={2} md={2} lg={2} xl={2} className="py-3">
                        <p className="fs-mini text-muted text-center pt-2"><span className="fw-bold">Social</span><br/>Instagram</p>
                        <h5 className="text-center m-0 p-0"><Displays a={this.props.page.years[0].landings.instagram} format="comma" /></h5>
                        <p className="pt-2 m-0 text-center text-muted fs-mini">{formatNumber('X%', this.props.page.years[0].landings.instagram/this.props.page.years[0].landings.total, false)}</p>
                    </Col>
                    <Col xs={12} sm={2} md={2} lg={2} xl={2} className="py-3">
                        <p className="fs-mini text-muted text-center pt-2"><span className="fw-bold">Social</span><br/>Threads</p>
                        <h5 className="text-center m-0 p-0"><Displays a={this.props.page.years[0].landings.threads} format="comma" /></h5>
                        <p className="pt-2 m-0 text-center text-muted fs-mini">{formatNumber('X%', this.props.page.years[0].landings.threads/this.props.page.years[0].landings.total, false)}</p>
                    </Col>
                    <Col xs={12} sm={2} md={2} lg={2} xl={2} className="py-3">
                        <p className="fs-mini text-muted text-center pt-2"><span className="fw-bold">Email</span><br/>Total</p>
                        <h5 className="text-center m-0 p-0"><Displays a={this.props.page.years[0].landings.email} format="comma" /></h5>
                        <p className="pt-2 m-0 text-center text-muted fs-mini">{formatNumber('X%', this.props.page.years[0].landings.email/this.props.page.years[0].landings.total, false)}</p>
                    </Col>
                    {/* <Col xs={12} sm={2} md={2} lg={2} xl={2} className="py-3">
                        <p className="fs-mini text-muted text-center pt-2"><span className="fw-bold">Other</span><br/>Referrals</p>
                        <p className="text-center fw-bold"><Displays a={this.props.page.years[0].landings.other_referrals} format="comma" /></p>
                    </Col> */}
                    <Col xs={12} sm={2} md={2} lg={2} xl={2} className="py-3">
                        <p className="fs-mini text-muted text-center pt-2"><span className="fw-bold">Other</span><br/>Referrals</p>
                        <h5 className="text-center m-0 p-0"><Displays a={this.props.page.years[0].landings.direct + this.props.page.years[0].landings.other_referrals} format="comma" /></h5>
                        <p className="pt-2 m-0 text-center text-muted fs-mini">{formatNumber('X%', (this.props.page.years[0].landings.direct + this.props.page.years[0].landings.other_referrals)/this.props.page.years[0].landings.total, false)}</p>
                    </Col>
                </Row>
            </div>
            <hr className="p-0 m-0 d-none" />

        </div>
    
    );
  }

}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(GaPage));