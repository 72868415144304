import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import {
  Row,
  Col,
  Nav,
  NavItem,
  Button,
  ButtonGroup,
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';

import WidgetTransactionsShows from '../../widgets/transactions/Shows';
import WidgetArticles from '../../widgets/ga/Section';
import WidgetShows from '../../widgets/content/Shows';
import WidgetSessions from '../../widgets/content/Sessions';
import WidgetKeywords from '../../widgets/content/Keywords';
import WidgetGaSection from '../../widgets/ga/Section';
import WidgetSpacer from '../../widgets/utils/Spacer';
import WidgetGaSections from '../../widgets/ga/Sections';
import WidgetGaField from '../../widgets/ga/Field';

import isScreen from '../../core/screenHelper';

import s from './Content.module.scss';

class Content extends React.Component {

  constructor(props) {
    super(props);

    let items = [
      { value: '', label: '----' },
      { value: 'channel:Google', label: 'Channel: Google' },
      { value: 'channel:Microsoft', label: 'Channel: Microsoft' },
      { value: '', label: '----' },
      { value: 'type:Generic', label: 'Type: Generic' },
      { value: 'type:Shows', label: 'Type: Shows' },
      { value: 'type:International', label: 'Type: International' },
      { value: 'type:- US', label: 'Type: Domestic' },
      { value: 'type:- NY', label: 'Type: NYC' },
    ];

    let ga_filter = {
      items: items,
      isLoaded: true
    }

    let q = props.location.state;

    let tabSelected = 0;
    if(q && q.tab) { 
      tabSelected = q.tab; 
    } else {
      if(this.props.match.params.tab) {
        tabSelected = parseInt(this.props.match.params.tab);
      }
    }

    let type = 'buzz';
    if(q && q.type) { 
      type = q.type; 
    } else {
      if(this.props.match.params.type) {
        type = this.props.match.params.type;
      }
    }

    const url = new URL(window.location.href);
    let baseUrl = '/#/app/content/';
    let baseTitle = 'Content Dashboard';
    if(url.hash.includes('dashboard')) {
      baseUrl = '/#/app/dashboard/';
      baseTitle = 'Content Dashboard';
    }

    this.state = {
      isLoaded: false,
      ga_filter: ga_filter,
      ga_filter_string: '',
      dropdownOpenTwo: false,
      print: false,
      tabSelected: tabSelected,
      base_url: baseUrl,
      title: baseTitle,
      type: type
    };

    this.toggleTwo = this.toggleTwo.bind(this);
    this.setTabs = this.setTabs.bind(this);
    this.print = this.print.bind(this);
  }

  componentDidMount() {
    this.setTabs();
  };

  setTabs(){

      var tabs = [
        {
          id: 0,
          icon: 'fa-home',
          name: 'Main<br/>Dashboard'
        },
        {
          id: 1,
          icon: 'fa-star',
          name: 'Site<br/>Traffic'
        },
        // {
        //   id: 2,
        //   icon: 'fa-bullseye',
        //   name: 'By<br/>Show'
        // },
        {
          id: 3,
          icon: 'fa-chart-bullseye',
          name: 'Search<br/>Positions'
        },
        // {
        //   id: 4,
        //   icon: 'fa-chart-line',
        //   name: 'Site<br/>Traffic'
        // },
      ];
      
      this.setState({
        tabs: tabs
      });

  }

  handleFilterChange = selectedOption => {

    var hasChanged = true;

    let ga_filter_string = '';
    for(var [i,selected] of selectedOption.entries()) {
      if(i > 0) {
        ga_filter_string += ',';
      }
      ga_filter_string += selected.value;
    }

    this.setState({ 
      ...this.state,
      hasChanged: hasChanged,
      ga_filter: {
        ...this.state.ga_filter,
        selected: selectedOption
      },
      ga_filter_string: ga_filter_string
    });
  };

  toggleTwo() {
    this.setState({
      dropdownOpenTwo: !this.state.dropdownOpenTwo,
    });
  }

  print = () => { 
    this.setState({
      print: !this.state.print
    });
  }

  selectAdmin = (e) => {
    e.preventDefault();
    this.props.history.push('/app/content/admin');
  }

  render() {
  
    return (
      <div>

        <Helmet>
          <title>SH4: Website Content</title>
        </Helmet>

        <div className="menuContainer lg">

          <Row>
            <Col xs={12} sm={6} md={6} lg={6} xl={6}>
              <Nav className="mt-4">
                <NavItem className="nopad-bottom nomargin-bottom">
                  
                  <h5 className="text-danger">Content Dashboard</h5>
                  {this.state.tabs ? (
                    <ButtonGroup>
                      {this.props.user.admin ? (
                        <Button color="default" className="mr-xs" onClick={((e) => this.selectAdmin(e))}><i className="fa fa-cog fa-light fa-lg text-danger" /></Button>
                      ) : null}
                      {this.state.tabs.map((tab, i) =>
                        <a key={i} href={this.state.base_url + tab.id}>
                          <Button
                            color={this.state.tabSelected === tab.id ? 'danger' : 'default'} className="mr-xs text-center"
                            active={this.state.tabSelected === tab.id}
                          >
                            <i className={['fa-light','fa-lg','d-lg-none','d-xl-none','pr-2',tab.icon,this.state.tabSelected === tab.id ? 'text-default' : 'text-danger'].join(' ')} />
                            <span className="d-md-down-none" dangerouslySetInnerHTML={{__html: tab.name}}></span>
                          </Button>
                        </a>
                      )}
                    </ButtonGroup>
                  ) : null}

                  <ButtonGroup className="d-none">
                    <ButtonDropdown isOpen={this.state.dropdownOpenTwo} toggle={this.toggleTwo} color="danger" className="mr-xs bg-white" active={true}>
                      <DropdownToggle><i className={['fa-light','fa-lg','text-danger','fa-star'].join(' ')} />
                      <span className="d-md-down-none gutter-left-fixed-1 gutter-right-fixed-1">Sections</span></DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem>Buzz</DropdownItem>
                        <DropdownItem>Guides</DropdownItem>
                        <DropdownItem>Venues</DropdownItem>
                        <DropdownItem divider />
                        <DropdownItem>Top Level</DropdownItem>
                      </DropdownMenu>
                    </ButtonDropdown>
                  </ButtonGroup>
                </NavItem>
              </Nav>
            </Col>
            <Col xs={12} sm={5} md={5} lg={5} xl={5} className="gutter-top-fixed-2 d-md-down-none">
              &nbsp;
            </Col>
            <Col xs={12} sm={1} md={1} lg={1} xl={1} className="gutter-top-fixed-4 d-none">
              <button className="header-link" onClick={() => this.print()}>
                {this.state.print ? (
                  <i className={['fa-light','fa-lg','fa-print','text-success'].join(' ')} />
                ) : (
                  <i className={['fa-light','fa-lg','fa-print'].join(' ')} />
                )}
              </button>
            </Col>
          </Row>
        </div>

        <div className={isScreen('xs') || isScreen('sm') ? 'm-1 mt-2' : 'm-3 mt-3'}>
          {this.state.tabSelected === 0 ? (
            <div className={s.sidesWrapper}>
              <div className={s.analyticsSide}>
                <Row>
                  <Col lg={12} xs={12}>
                    <WidgetArticles name="Top Buzz Articles" dashboard={true} affiliate={false} section="buzz" />
                    <WidgetTransactionsShows affiliate={false} dashboard={true} content={true} />
                  </Col>
                  <Col xs={12} lg={6} xl={4} className="gutter-bottom-fixed-2">
                    {/* <WidgetGeo affiliate={false} dashboard={true} /> */}
                  </Col>
                  <Col xs={12} lg={6} xl={4} className="gutter-bottom-fixed-2">
                    {/* <WidgetMargin dashboard={true} /> */}
                  </Col>
                </Row>
               
              </div>
              <div className={s.analyticsSide}>
                <Row>
                  <Col xs={12} md={12} lg={12} xl={12} className={[s.lastSideElement,''].join()}>
                    <div className="pb-xlg">
                      <Row>
                        <Col xs={12} md={12} lg={6} xl={12}>
                          <div className="gutter-bottom-fixed-3">
                            <WidgetGaSections affiliate={false} dashboard={true} user={this.props.user} />
                          </div>
                        </Col>
                        {/* <Col xs={12} md={12} lg={6} xl={12}>
                          <div className="gutter-bottom-fixed-3">
                            <WidgetSessions affiliate={false} dashboard={true} />
                          </div>
                        </Col> */}
                        <Col xs={12} md={12} lg={6} xl={12}>
                          <div className="gutter-bottom-fixed-3">
                            <WidgetGaField field="referrers" title="Referrers" field_header="Site" className="mt-0" sales_metric="views" description="3rd party sites driving traffic" affiliate={false} />
                          </div>
                        </Col>
                        <Col xs={12} md={12} lg={6} xl={12}>
                          <>
                            {/* <WidgetSessions affiliate={false} dashboard={true} /> */}
                            <div className="gutter-bottom-fixed-1">&nbsp;</div>
                          </>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col xs={12} md={6} lg={6} xl={12} className={[s.lastSideElement,isScreen('xl') ? 'd-none' : ''].join()}>
                    <div className="pb-xlg h-100">
                      {/* <WidgetSessions affiliate={false} dashboard={true} /> */}
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          ): null}

          {this.state.tabSelected === 1 ? (
            <div>
              <WidgetGaSection section={this.state.type} name="Section Totals" print={false} tab={1} />
            </div>
          ): null}

          {this.state.tabSelected === 2 ? (
            <div>
              <WidgetShows print={this.state.print} />
            </div>
          ): null}
          
          {this.state.tabSelected === 3 ? (
            <div>
              <WidgetKeywords print={this.state.print} />
            </div>
          ): null}

          {this.state.tabSelected === 4 ? (
            <div>
              <WidgetSessions print={this.state.print} />
            </div>
          ): null}
          
          {this.state.tabSelected === 5 ? (
            <div>
              <WidgetGaSection section='top' name='Top Level' print={false} />
            </div>
          ): null}

          {this.state.tabSelected === 6 ? (
            <div>
              <WidgetGaSection section='shows' name='Show Pages' print={false} />
            </div>
          ): null}

          {this.state.tabSelected === 7 ? (
            <div>
              <WidgetGaSection section='venues' name='Venues' print={false} />
            </div>
          ): null}
          
          {this.state.tabSelected === 8 ? (
            <div>
              <WidgetGaSection section='guides' name='Guide Articles' print={false} />
            </div>
          ): null}

          {this.state.tabSelected === 9 ? (
            <div>
              <WidgetGaSection section='other' name='Other Pages' print={false} />
            </div>
          ): null}

          <WidgetSpacer print={this.state.print} />
        
        </div>


      </div>
    );  

  }
}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(Content));
