import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { DateTime } from 'luxon/build/node/luxon';

import Widget from '../../../components/Widget';

import { Row, Col, Input, Table, Button } from 'reactstrap';

import { scurl, filterUpdated } from '../../../core/utils.js';

import Loading from '../../../components/Loading';

class WidgetKeywordAdmin extends React.Component {

  static propTypes = {
    user: PropTypes.object,
    className: PropTypes.string,
    type: PropTypes.string
  };

  static defaultProps = {
    className: '',
    type: null
  }

  constructor(props) {
    super(props); 

    let items = [
      { value: '', label: '----' },
      { value: 4, label: 'Stories Carousel Exists' }
    ];

    let sem_filter = {
      items: items,
      isLoaded: true
    }

    this.state = {
      isLoaded: false,
      sem_filter: sem_filter
    };

    this._isMounted = false;

    this.getCampaigns = this.getCampaigns.bind(this);
    this.onKeyPressed = this.onKeyPressed.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;

    this.getCampaigns();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if(filterUpdated(prevProps.filter, this.props.filter, 'SERP CAMPAIGNS WIDGET')) {
      this.getCampaigns();
    } else {
      if(prevProps.sem_filter !== this.props.sem_filter) {
        this.getCampaigns();
      }
    }
  }

  componentDidCatch(error, info) {
    if(this._isMounted) {
      this.setState({
        error: {
          didCatch: true,
          error: error,
          info: info,
          status: error.status || -99,
          message: error.message || 'Error occured'
        }
      });
    }
  }

  getCampaigns = function() {

    var opts = {
      path: '/v2/serp/shadowing',
      type: 'universal'
    };

    //console.log(opts);
    this.setState({
      isLoaded: false,
      results: []
    }, function() {
      scurl(opts, function(err, results) {
        if(this._isMounted) {
          if(err) {
            this.setState({
              error: {
                json: err,
                status: err.status || -99,
                message: err.message || 'Error occured'
              }
            });
          } else {
            if(results) {
              this.setState({
                isLoaded: true,
                original: results,
                results: results,
              });
            } else {
              this.setState({
                error: {
                  json: 'No data returned',
                  status: -99,
                  message: 'Error occured: No data returned'
                }
              });
            }
          }
        } else {
          console.log('handled unmount');
        }
      }.bind(this));
    }.bind(this));
  }

  onKeyPressed(e) {
    if (e.key === 'Enter') {

      var opts = {
        path: '/v2/serp/keywords/insert?keyword=' + e.target.value,
        type: 'simple'
      };
  
      scurl(opts, function(err, results) {
        if(this._isMounted) {
          if(err) {
            this.setState({
              error: {
                json: err,
                status: err.status || -99,
                message: err.message || 'Error occured'
              }
            });
          } else {
            if(results) {
              this.getCampaigns();
            } else {
              console.log('No results')
            }
          }
        } else {
          console.log('handled unmount');
        }
      }.bind(this));
    }
  }

  fire = () => { 
  
      this.setState({
        isLoaded: false
      }, function() {
        
        var url = '/v2/serp/fire';
  
        var opts = {
          path: url,
          type: 'simple'
        };
  
        scurl(opts, function(err, results) {
          if(err) {
            this.setState({
              error: {
                json: err,
                status: err.status || -99,
                message: err.message || 'Error occured'
              }
            });
          } else {
            this.setState({
              isLoaded: true,
              fire: results,
            });
          }
        }.bind(this));
  
      });
    }

  render() {
    return (

      <Widget title={
        <Row>
          <Col xs={12} sm={8} md={8} lg={9} xl={9}>
            <div className="d-flex justify-content-between flex-wrap">
              <h5 className="text-danger">Create Keyword Tracking<small className="text-muted pl-2">Sorted in descending order by orders generated via paid search spending.</small></h5>
            </div>
          </Col>
          <Col xs={12} sm={5} md={4} lg={3} xl={3}>
            <Input href="showquery" placeholder="Add keyword..." onKeyDown={this.onKeyPressed} />
          </Col>
        </Row>
      }>
        
        <Loading loading={!this.state.isLoaded} error={this.state.error} pad={10} />
        
        {this.state.isLoaded ? (

          <div className="gutter-top-fixed-2 gutter-bottom-fixed-2">

            <Table>
              <thead>
                <tr>
                  <th width="40%">Keyword</th>
                  <th width="30%" className="text-center">Type</th>
                  <th width="30%" className="text-center">Last Updated</th>
                </tr>
              </thead>
              <tbody> 
                {this.state.results.map((keyword, i) =>
                  <tr key={i}>
                    <td className="text-left">
                      <span className="text-capitalize fs-mini">{keyword.keyword }</span>
                    </td>
                    <td className="text-center">
                      <span className="text-capitalize fs-mini"></span>
                    </td>
                    <td className="text-center">
                    <span className="text-capitalize fs-mini">{DateTime.fromISO(keyword.updatedAt).toISODate()}</span>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>

            <hr/>

            <Row className="gutter-top-fixed-2">
              <Col xs={12} sm={6} md={3} lg={3} xl={3}>
                <Button className="btn-success text-white" onClick={() => this.fire()}>
                  <i className="fa-light fa-bolt" /> Fire
                </Button>
              </Col>
            </Row>
          
          </div>
          
        ) : null}

      </Widget>
  )}

}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(WidgetKeywordAdmin));
