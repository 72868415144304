import React, { useState } from 'react';
import { Row, Col, Button, Table } from 'reactstrap';

import { formatNumber } from '../../../../core/utils';

const ContentShow = (props) => {

  const [expand, setExpand] = useState(false);

  const handleExpand = function() {
    setExpand(!expand);
    const section = document.querySelector('#block_' + props.show.slug);
    if(section) {
      section.scrollIntoView( { behavior: 'smooth', block: 'start' } );
    }
  }

  return (
    <div className={props.className}>
      <Row className={expand ? 'bg-primary p-3 pt-4 pb-4':'pb-4'} id={'block_' + props.show.product.slug}>
        <Col xs={12} sm={4} md={4} lg={4} xl={4}>
          <Row className="">
            <Col xs={2} sm={2} md={2} lg={1} xl={1} className="d-print-none" onClick={() => handleExpand(props.show.product.slug)}>
              <h6>
                <Button className="btn btn-default btn-xs inline-block text-inline" onClick={() => handleExpand(props.show.product.slug)}>
                  <i className={'fa-light ' + (expand ? 'fa-minus' : 'fa-plus')} />
                </Button>
              </h6>
            </Col>
            <Col xs={10} sm={10} md={10} lg={11} xl={11}>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <h5 className={'text-capitalize ' + (expand ? ' fw-bold' : '')}>
                    <span className={expand ? 'text-white':'text-muted'}></span><span className={expand ? 'text-white':''}>{props.show.product.name.toUpperCase()}</span>
                  </h5>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className={expand ? 'bg-primary p-3 pt-4 pb-4':''} id={'block_' + props.show.product.slug}>
        <Col xs={12} sm={2} md={2} lg={2} xl={2} className="text-center">
          {!expand ? (
              <div className="card p-4">
                <h6 className="text-center text-muted pb-4">Articles</h6>
                <h4 className="text-center text-muted pb-4">{formatNumber('X,', props.show.articles.length, false)}</h4>
              </div>
            ) : null}
        </Col>
        <Col xs={12} sm={2} md={2} lg={2} xl={2} className="text-left">
          {!expand ? (
              <div className="card p-4">
                <h6 className="text-center text-muted pb-4">Ticket Sales</h6>
                {props.show && props.show.sales ? (
                  <h4 className="text-center text-muted pb-4">{formatNumber('$XM', props.show.sales.face, false)}</h4>
                ) : null}
              </div>
            ) : null}
        </Col>
        <Col xs={12} sm={2} md={2} lg={2} xl={2} className="text-left">

        </Col>
        <Col xs={12} sm={2} md={2} lg={2} xl={2} className="text-left">

        </Col>
      </Row>

      {expand ? (
          <div className="w-100 bg-white p-0 m-0">
            <Row className="gutter-top-fixed-4">
              <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                <h5 className="">Search Metrics</h5>
                <div className="gutter-top-fixed-1 bg-light border">
                  <Table className="fs-mini p-0 m-0 border">
                    <tbody> 
                      <tr>
                        <td className="p-0 m-0 pt-3 pl-3" width="50%">
                          <h6 className="p-0 m-0" style={{height: 30}}>Impressions</h6>
                        </td>
                        <td className="p-0 m-0 pt-3 pl-3 text-center" width="50%">
                          <h6 className="p-0 m-0">{formatNumber('X,', props.keyword.paid_daily.impressions, false)}</h6>
                        </td>
                      </tr>
                      <tr>
                        <td className="p-0 m-0 pt-3 pl-3" width="50%">
                          <h6 className="p-0 m-0" style={{height: 30}}>Visits</h6>
                        </td>
                        <td className="p-0 m-0 pt-3 pl-3 text-center" width="50%">
                          <h6 className="p-0 m-0">{formatNumber('X,', props.keyword.paid_daily.clicks, false)}</h6>
                        </td>
                      </tr>
                      <tr>
                        <td className="p-0 m-0 pt-3 pl-3" width="50%">
                          <h6 className="p-0 m-0" style={{height: 30}}>Orders</h6>
                        </td>
                        <td className="p-0 m-0 pt-3 pl-3 text-center" width="50%">
                          <h6 className="p-0 m-0">{formatNumber('X.X', props.keyword.paid_daily.orders, false)}</h6>
                        </td>
                      </tr>
                      <tr>
                        <td className="p-0 m-0 pt-3 pl-3" width="50%">
                          <h6 className="p-0 m-0" style={{height: 30}}>Conv %</h6>
                        </td>
                        <td className="p-0 m-0 pt-3 pl-3 text-center" width="50%">
                          <h6 className="p-0 m-0">{formatNumber('X.X', (parseFloat(props.keyword.paid_daily.orders/props.keyword.paid_daily.clicks)*100), false)}%</h6>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                <h5 className="">Funnel Metrics</h5>
                <div className="gutter-top-fixed-1 bg-light border">
                {/* <Table className="fs-mini nopad-bottom nomargin-bottom border">
                    <thead>
                      <tr>
                          <th width="33%">Metric</th>
                          <th className="text-center" width="33%">Value</th>
                          <th className="text-center" width="33%">PY +/-</th>
                      </tr>
                    </thead>
                    <tbody> 
                      <tr>
                        <td>
                          <h6 style={{height: 30}}>Starts</h6>
                        </td>
                        <td className="text-center">
                          <h6>{formatNumber('X,', props.keyword.years[0].clicks, false)}</h6>
                        </td>
                        <td className="text-center">
                          {props.keyword.years[1] ? (
                            <h6><Displays a={props.keyword.years[0].clicks} b={props.keyword.years[1].clicks} format="percent" /></h6>
                          ) : (
                            <span>--</span>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h6 style={{height: 30}}>Conversion %</h6>
                        </td>
                        <td className="text-center">
                          <h6>{formatNumber('X.X%', props.keyword.years[0].conversion_percentage, false)}</h6>
                        </td>
                        <td className="text-center">
                          {props.keyword.years[1] ? (
                            <h6><Displays a={props.keyword.years[0].conversion_percentage} b={props.keyword.years[1].conversion_percentage} format="percent" /></h6>
                          ) : (
                            <span>--</span>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <h6 style={{height: 30}}>Orders</h6>
                        </td>
                        <td className="text-center">
                          <h6>{formatNumber('X,', props.keyword.years[0].orders, false)}</h6>
                        </td>
                        <td className="text-center">
                          {props.keyword.years[1] ? (
                            <h6><Displays a={props.keyword.years[0].orders} b={props.keyword.years[1].orders} format="percent" /></h6>
                          ) : (
                            <span>--</span>
                          )}
                        </td>
                      </tr>
                    </tbody>
                </Table> */}
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={4} xl={4}>
                <h5 className="">Revenue</h5>
                <div className="gutter-top-fixed-1 bg-light border">
     
                </div>
              </Col>
            </Row>
          </div>
      ) : null}

      {/* <hr/> */}

    </div>
  );
};

export default ContentShow;