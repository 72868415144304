import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { DateTime } from 'luxon';

import {
  Row,
  Col
} from 'reactstrap';

import { ResponsiveContainer, Line, LineChart, Label, XAxis, YAxis, ReferenceLine, Tooltip, Legend} from 'recharts';

import Widget from '../../../components/Widget';
import Displays from '../../../components/Displays';
import Loading from '../../../components/Loading';
import { surl } from '../../../core/utils.js';
import { filterUpdated, nFormatter, formatNumber } from '../../../core/utils';
import isScreen from '../../../core/screenHelper';

class WidgetBwAdsales extends React.Component {

  static propTypes = {
    engagement: PropTypes.object,
    chartAspectWidth: PropTypes.number,
    chartAspectHeight: PropTypes.number,
    print: PropTypes.bool,
    width: PropTypes.number,
    height: PropTypes.number,
    chart: PropTypes.bool,
    fixed_years: PropTypes.number,
    fees: PropTypes.bool,
    chart_timespan: PropTypes.string,
    title: PropTypes.bool,
    chart_metric: PropTypes.string,
    className: PropTypes.string,
  };

  static defaultProps = {
    engagement: null,
    chartAspectWidth: 6,
    chartAspectHeight: 1,
    print: false,
    chart: true,
    width: 1050,
    height: 400,
    fixed_years: 4,
    chart_timespan: 'week',
    fees: true,
    title: true,
    chart_metric: 'spend',
    className: ''
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      chart_metric: this.props.chart_metric,
      chart_timespan: this.props.chart_timespan,
      fixed_years: this.props.fixed_years,
      chartAspectWidth: this.props.chartAspectWidth,
      chartAspectHeight: this.props.chartAspectHeight
    }

    this._isMounted = false;
    this.loadCampaigns = this.loadCampaigns.bind(this);
    this.setChartData = this.setChartData.bind(this);
    this.salesSummaryDataFormat = this.salesSummaryDataFormat.bind(this);
    this.salesSummaryDateFormat = this.salesSummaryDateFormat.bind(this);
    this.setChartAspect = this.setChartAspect.bind(this);
    this.areaLabelFormat = this.areaLabelFormat.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadCampaigns();
    this.setChartAspect();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if(filterUpdated(prevProps.filter, this.props.filter, 'AD SALES WIDGET')) {
      this.loadCampaigns();
    }
  }

  componentDidCatch(error, info) {
    if(this._isMounted) {
      this.setState({
        error: {
          didCatch: true,
          error: error,
          info: info,
          status: error.status || -99,
          message: error.message || 'Error occured'
        }
      });
    }
  }

  loadCampaigns() {
    
    this.setState({
      isLoaded: false,
      results: []
    }, function() {

      var opts = {
        path: '/v2/adsales/totals',
        type: 'universal'
      };

      if(this.props.engagement) {
        opts.show_code = this.props.engagement.show.name;
      }

      opts.timespans = 1;

      opts.years = this.state.fixed_years;

      var url = surl(opts);
      //console.log(url);

      fetch(url)
        .then(res => res.json())
        .then(
          (data) => {
            if(this._isMounted) {
              if(data.results && data.results.years) {
                this.setState({
                  isLoaded: true,
                  results: data.results,
                }, function() {
                  this.setChartData();
                });
              } else {
                this.setState({
                  isLoaded: false,
                  error: {
                    message: 'No data returned'
                  }
                });
              }
            }
          }, (error) => {
            if(this._isMounted) {
              this.setState({
                  isLoaded: false,
                  error: error
              });
            }
      });

    });
  
  }

  setChartData() {
    var chartData = [];
        
    if(this.state.results && this.state.results.years && this.state.results.years[0] && this.state.results.years[0].weeks.length > 0) {


      this.state.results.years[0].weeks.forEach(function(block, i) {
        
        var d = {
          name: block.weekending,
          year1: block.spend
        };

        chartData.push(d);
       
      });

      var reflines = [];

      var min = 0.9;
      var max = 1.1;
      if(this._isMounted) {
        this.setState({
          chart: {
            data: chartData,
            reflines: reflines,
            properties: {
              max: max,
              min: min
            }
          }
        });
      }
    }
  }

  setChartAspect() {
    if((isScreen('xs') || isScreen('sm'))) {
      this.setState({
        chartAspectWidth: 2,
        chartAspectHeight: 1
      });
    } else {
      this.setState({
        chartAspectWidth: this.props.chartAspectWidth,
        chartAspectHeight: this.props.chartAspectHeight
      });
    }
  }

  salesSummaryDataFormat(x) {
    if(this.state.metric === 'advertisers') {
      return parseInt(x);
    } else {
      return '$' + nFormatter(x);
    }
  };

  salesSummaryDateFormat(x) {
    return DateTime.fromISO(x).toFormat('MM-dd');
  };

  areaLabelFormat(x) {
    return x
  };

  render() {

    var title = <h5><span className="text-danger">Totals</span> {this.state.isLoaded ? (<small className="text-muted">From {DateTime.fromISO(this.state.results.dates[0].start).toLocaleString(DateTime.DATE_FULL)} to {DateTime.fromISO(this.state.results.dates[0].end).toLocaleString(DateTime.DATE_FULL)}.</small> ) : null}</h5>;

    return (

      <div>

        <Widget title={title} className={this.props.className}>

          <Loading loading={!this.state.isLoaded} pad={10} />

          {this.state.isLoaded ? (
            <div className="gutter-top-fixed-2">
              <Row>
                <Col xs={12} sm={6} md={6} lg={3} xl={3} className="gutter-bottom-fixed-2">
                  <p className="fs-mini text-muted text-center">Avg Weekly Advertisers</p>
                  <h4 className="text-center">{formatNumber('X.X', this.state.results.years[0].totals.averages.advertisers, false)}</h4>
                </Col>
                <Col xs={12} sm={6} md={6} lg={3} xl={3} className="gutter-bottom-fixed-2">
                  <p className="fs-mini text-muted text-center">Avg Weekly Spend</p>
                  <h4 className="text-center">$<Displays a={parseInt(this.state.results.years[0].totals.averages.advertiser_spend)} format="comma" /></h4>
                </Col>
                <Col xs={12} sm={6} md={6} lg={3} xl={3}>
                  <p className="fs-mini text-muted text-center">Total Spend</p>
                  <h4 className="text-center">$<Displays a={parseInt(this.state.results.years[0].totals.spend)} format="comma" /></h4>
                </Col>
                <Col xs={12} sm={6} md={6} lg={3} xl={3} className="gutter-bottom-fixed-2">
                  <p className="fs-mini text-muted text-center">Avg Weekly Spend</p>
                  <h4 className="text-center">$<Displays a={parseInt(this.state.results.years[0].totals.averages.spend)} format="comma" /></h4>
                </Col>
              </Row>

              {this.state.isLoaded && this.state.chart && this.state.chart.data ? (
                <div className="gutter-top-fixed-3">

                  {this.props.print ? (
                    <div>
                      {this.props.chart && this.state.chart.data.length > 1 ? (
                        <LineChart data={this.state.chart.data} margin={{top: 5, right: 20, left: 0, bottom: 5}} height={this.props.height} width={this.props.width} >
                          <defs>
                            <linearGradient id="colorPY" x1="0" y1="0" x2="0" y2="1">
                              <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                              <stop offset="95%" stopColor="#8884d8" stopOpacity={0.4}/>
                            </linearGradient>
                            <linearGradient id="colorCY" x1="0" y1="0" x2="0" y2="1">
                              <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
                              <stop offset="95%" stopColor="#82ca9d" stopOpacity={0.4}/>
                            </linearGradient>
                          </defs>
                          <XAxis tickFormatter={this.salesSummaryDateFormat} tick={{fontSize: 10}} dataKey="name" padding={{left: 0, right: 0}} />
                          <YAxis tick={{fontSize: 10}} tickSize={3} tickFormatter={this.salesSummaryDataFormat} domain={[dataMin => this.state.chart.properties.min, dataMax => (dataMax * this.state.chart.properties.max)]} />
                          {this.state.chart.reflines.length > 0 ? (
                            this.state.chart.reflines.map((refline, i) =>
                              <ReferenceLine key={i} x={refline.x} isFront={true} stroke="lightgray">
                                <Label value={refline.label} offset={refline.offset} position="top" fill="black" style={{fontSize: '0.8rem' }} />
                              </ReferenceLine>
                            )
                          ) : null }

                          <Line name="2021" type="monotone" dataKey="year1" stroke="#82ca9d" strokeWidth={2} />

                          {this.state.chart.data[0].year2 ? (
                            <Line name="2020" type="monotone" dataKey="year2" stroke="#ccc" dot={{ stroke: '#ccc', strokeWidth: 1 }} />
                          ) : null }
                          {this.state.chart.data[0].year3 ? (
                            <Line name="2019" type="monotone" dataKey="year3" stroke="#0087a9" dot={{ stroke: '#0087a9', strokeWidth: 1 }} />
                          ) : null }
                          {this.state.chart.data[0].year4 ? (
                            <Line name="2018" type="monotone" dataKey="year4" stroke="#ffc647" dot={{ stroke: '#ffc647', strokeWidth: 1 }} />
                          ) : null }
                          {this.state.chart.data[0].year5 ? (
                            <Line name="2017" type="monotone" dataKey="year5" stroke="#754c7f" dot={{ stroke: '#754c7f', strokeWidth: 1 }} />
                          ) : null }
                          {this.state.chart.data[0].year6 ? (
                            <Line name="2016" type="monotone" dataKey="year6" stroke="#db3920" dot={{ stroke: '#db3920', strokeWidth: 1 }} />
                          ) : null }

                          {this.state.chart.data[0].year3 ? (
                            <Legend verticalAlign="top" height={36} align="right" iconType="square" />
                          ) : null }
                        </LineChart>
                        
                      ) : null}
                    </div>
                  ) : (
                    <ResponsiveContainer width='100%' aspect={this.state.chartAspectWidth/this.state.chartAspectHeight} >
                      <LineChart data={this.state.chart.data} margin={{top: 5, right: 20, left: 0, bottom: 5}} height={this.props.height} width={this.props.width} >
                          <defs>
                            <linearGradient id="colorPY" x1="0" y1="0" x2="0" y2="1">
                              <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8}/>
                              <stop offset="95%" stopColor="#8884d8" stopOpacity={0.4}/>
                            </linearGradient>
                            <linearGradient id="colorCY" x1="0" y1="0" x2="0" y2="1">
                              <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
                              <stop offset="95%" stopColor="#82ca9d" stopOpacity={0.4}/>
                            </linearGradient>
                          </defs>
                          <XAxis tickFormatter={this.salesSummaryDateFormat} tick={{fontSize: 10}} dataKey="name" padding={{left: 0, right: 0}} />
                          <YAxis tick={{fontSize: 10}} tickSize={3} tickFormatter={this.salesSummaryDataFormat} domain={[ dataMin => (dataMin * this.state.chart.properties.min), dataMax => (dataMax * this.state.chart.properties.max) ]} />
                          {this.state.chart.reflines.length > 0 ? (
                            this.state.chart.reflines.map((refline, i) =>
                              <ReferenceLine key={i} x={refline.x} isFront={true} stroke="lightgray">
                                <Label value={refline.label} offset={refline.offset} position="top" fill="black" style={{fontSize: '0.8rem' }} />
                              </ReferenceLine>
                            )
                          ) : null }

                          <Line name="2021" type="monotone" dataKey="year1" stroke="#82ca9d" strokeWidth={2} />

                          {this.state.chart.data[0].year2 ? (
                            <Line name="2020" type="monotone" dataKey="year2" stroke="#ccc" dot={{ stroke: '#ccc', strokeWidth: 1 }} />
                          ) : null }
                          {this.state.chart.data[0].year3 ? (
                            <Line name="2019" type="monotone" dataKey="year3" stroke="#0087a9" dot={{ stroke: '#0087a9', strokeWidth: 1 }} />
                          ) : null }
                          {this.state.chart.data[0].year4 ? (
                            <Line name="2018" type="monotone" dataKey="year4" stroke="#ffc647" dot={{ stroke: '#ffc647', strokeWidth: 1 }} />
                          ) : null }

                          {this.state.chart.data[0].year3 ? (
                            <Legend verticalAlign="top" height={36} align="right" iconType="square" />
                          ) : null }

                          <Tooltip />
                        </LineChart>
                    </ResponsiveContainer>
                  )}
                </div>
              ): null}

            </div>
          ) : null}

        </Widget>

      </div>
      
    );
  }

}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(WidgetBwAdsales));
