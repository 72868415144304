import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import s from './Dates.module.scss';

import moment from 'moment';
import momentPropTypes from 'react-moment-proptypes';

import omit from 'lodash/omit';

import { Row, Col, Button } from 'reactstrap';

import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';

import { DateRangePicker } from 'react-dates';
import isInclusivelyBeforeDay from './react-dates/isInclusivelyBeforeDay';
import ThemedStyleSheet from './react-dates/ThemedStyleSheet';
//import aphroditeInterface from 'react-with-styles-interface-aphrodite';
import DefaultTheme from './react-dates/theme';
import 'react-dates/initialize';

import { updateFilter } from '../../actions/filter';
import { dateShortcut, isWeeks } from '../../core/utils';


class Dates extends React.Component {
  
  static propTypes = {
    toggleDates: PropTypes.func.isRequired,
    page: PropTypes.bool,
    autoFocus: PropTypes.bool,
    autoFocusEndDate: PropTypes.bool,
    initialStartDate: momentPropTypes.momentObj,
    initialEndDate: momentPropTypes.momentObj,
    user: PropTypes.object.isRequired
  };

  static defaultProps = {
    page: false,
    autoFocus: false,
    autoFocusEndDate: false,
    initialStartDate: null,
    initialEndDate: null,
    startDateId: 'startDate',
    startDatePlaceholderText: 'Start Date',
    endDateId: 'endDate',
    endDatePlaceholderText: 'End Date',
    orientation: 'horizontal',
    anchorDirection: 'left',
    horizontalMargin: 0,
    disableScroll: false,
    initialVisibleMonth: () => moment().startOf('month'),
    minimumNights: 0,
    enableOutsideDays: false,
    isDayBlocked: () => false,
    isOutsideRange: day => !isInclusivelyBeforeDay(day, moment().add(1,'year')),
    isDayHighlighted: () => false,
    displayFormat: 'YYYY-MM-DD',
    hideKeyboardShortcutsPanel: true
  };

  constructor(props) {

    super(props);



    let focusedInput = null;
    if (props.autoFocus) {
      focusedInput = 'startDate';
    } else if (props.autoFocusEndDate) {
      focusedInput = 'endDate';
    }

    var startDate = moment().add(-7,'day');
    var endDate = moment().add(-1,'day');
    if(this.props.filter.dates.start && this.props.filter.dates.end) {
      startDate = moment(this.props.filter.dates.start, 'YYYY-MM-DD');
      endDate = moment(this.props.filter.dates.end, 'YYYY-MM-DD');
    }

    var sliderDefault = [-15,0];
    if(isWeeks(this.props.filter)) {
      var cw = moment().endOf('week').add(1,'day');
      var start = moment(this.props.filter.dates.start, 'YYYY-MM-DD').endOf('week').add(1,'day');
      var start_diff = start.diff(cw, 'weeks');
      var end = moment(this.props.filter.dates.end, 'YYYY-MM-DD').add(1,'week');
      var end_diff = end.diff(cw, 'weeks');
      sliderDefault = [start_diff,end_diff];
    }

    var startText = moment().add(sliderDefault[0], 'week').startOf('week').add(1,'day').format('YYYY-MM-DD');
    var endText = moment().add(sliderDefault[1], 'week').endOf('week').add(1,'day').format('YYYY-MM-DD');
    var sliderRangeText = moment(startText, 'YYYY-MM-DD').endOf('week').add(1,'day').format('MMM DD') + ' to ' + moment(endText, 'YYYY-MM-DD').format('MMM DD');

    var marks = {
      '-51': moment().add(-51, 'week').endOf('week').add(1,'day').format('MM/DD'),
      '-30': moment().add(-30, 'week').endOf('week').add(1,'day').format('MM/DD'),
      '-15': moment().add(-15, 'week').endOf('week').add(1,'day').format('MM/DD'),
      0: {
        style: {
          color: 'black',
        },
        label: <strong>{moment().endOf('week').add(1,'day').format('MM/DD')}</strong>,
      },
      10: moment().add(10, 'week').endOf('week').add(1,'day').format('MM/DD'),
      20: moment().add(15, 'week').endOf('week').add(1,'day').format('MM/DD')
    };

    this.state = {
      focusedInput,
      startDate: startDate,
      endDate: endDate,
      verticalHeight: 500,
      marks: marks,
      sliderDefault: sliderDefault,
      sliderRangeText: sliderRangeText,
      sliderDisabled: true
    };

    this.onDatesChange = this.onDatesChange.bind(this);
    this.onFocusChange = this.onFocusChange.bind(this);
    this.afterStateChange = this.afterStateChange.bind(this);
    this.shortcut = this.shortcut.bind(this);
    this.sliderOnChange = this.sliderOnChange.bind(this);
    this.sliderSet = this.sliderSet.bind(this);
    this.valuesDirty = false;
  }

  componentDidMount() {}

  onDatesChange({ startDate, endDate }) {
    
    // check if either date has actually changed
    if(startDate && !startDate.isSame(this.state.startDate, 'day')) {
      console.log('start date changed'); 
      this.valuesDirty = true;
    }
    if(endDate && !endDate.isSame(this.state.endDate, 'day')) {
      console.log('end date changed'); 
      this.valuesDirty = true;
    }

    this.setState({
      startDate: startDate,
      endDate: endDate
    });

  }

  onFocusChange(focusedInput) {
    this.setState({ 
      focusedInput: focusedInput 
    }, function() {
      if(!this.state.focusedInput) {
        this.afterStateChange();
      }
    });
  }

  afterStateChange() {
    
    var before = Object.assign({}, this.props.filter);

    if(this.valuesDirty) {

      var set = {
        id: 99,
        start: moment(this.state.startDate).format('YYYY-MM-DD'),
        end: moment(this.state.endDate).format('YYYY-MM-DD')
      };

      var dates = dateShortcut(set);

      before.dates = dates;
      this.props.dispatch(updateFilter(before));
      this.props.toggleDates();

    }
  }

  shortcut(t) {

    var before = Object.assign({}, this.props.filter);

    var set = {
      id: t
    };

    var dates = dateShortcut(set);

    this.setState({
      startDate: moment(dates.start, 'YYYY-MM-DD'),
      endDate: moment(dates.end, 'YYYY-MM-DD')
    }, function() {
      before.dates = dates;
      this.props.dispatch(updateFilter(before));
      this.props.toggleDates();
    });
  }

  sliderOnChange(value) {

    var start = moment().add(value[0], 'week').startOf('week').add(1,'day').format('YYYY-MM-DD');
    var end = moment().add(value[1], 'week').endOf('week').add(1,'day').format('YYYY-MM-DD');

    var sliderRangeText = moment(start, 'YYYY-MM-DD').endOf('week').add(1,'day').format('MMM DD') + ' to ' + moment(end, 'YYYY-MM-DD').format('MMM DD');

    this.setState({
      sliderRangeText: sliderRangeText,
      sliderRange: {
        start: start,
        end: end
      },
      sliderDisabled: false
    });
  }

  sliderSet() {

    var set = {
      id: 99,
      start: this.state.sliderRange.start,
      end: this.state.sliderRange.end
    };

    console.log(set);

    var dates = dateShortcut(set);

    var before = Object.assign({}, this.props.filter);
    before.dates = dates;
    this.props.dispatch(updateFilter(before));
    this.props.toggleDates();

  }

  render() {

    ThemedStyleSheet.registerTheme({
      reactDates: {
        ...DefaultTheme.reactDates,
        color: {
          ...DefaultTheme.reactDates.color,
          highlighted: {
            backgroundColor: '#777',
            backgroundColor_active: '#777',
            backgroundColor_hover: '#777',
            color: '#777',
            color_active: '#186A3B',
            color_hover: '#186A3B',
          },
        },
        font: {
          ...DefaultTheme.reactDates.font,
          size: 10,
          captionSize: 10,
          input: {
            size: 10,
            weight: 300,
            lineHeight: '24px',
            size_small: 10,
            lineHeight_small: '18px',
            letterSpacing_small: '0.2px',
            styleDisabled: 'italic'
          }
        }
      },
    });
    ThemedStyleSheet.registerTheme(DefaultTheme);

    const { focusedInput, startDate, endDate, verticalHeight } = this.state;

    const props = omit(this.props, [
      'autoFocus',
      'autoFocusEndDate',
      'initialStartDate',
      'initialEndDate',
      'match', 
      'location',
      'history',
      'staticContext',
      'transDateDisplay',
      'dispatch',
			'filter',
      'toggleDates',
      'page'
    ]);

    return (
      <section className={`${s.notifications} card navbar-notifications`}>
        <div>

          <div className="gutter-top-fixed-2 text-center w-100">
            
            <h6 className="fw-semi-bold">CHOOSE DATES</h6>
            <DateRangePicker
              {...props}
              onDatesChange={this.onDatesChange}
              onFocusChange={this.onFocusChange}
              focusedInput={focusedInput}
              startDate={startDate}
              endDate={endDate}
              verticalHeight={verticalHeight} 
              withFullScreenPortal={false}
              numberOfMonths={1}
              noBorder
              className="w-100 p-0 m-0"
            />
          </div>

          <hr/>

          <div className="gutter-left-fixed-2 gutter-right-fixed-2 gutter-bottom-fixed-4">
            
            <div className="gutter-top-fixed-2 text-center">
              <h6 className="fw-semi-bold">SHORTCUTS</h6>
              <Row>
                <Col xs={6} sm={6} md={4} lg={4} className="gutter-top-fixed-1"><Button className="fs-mini" color="warning" onClick={((e) => this.shortcut(5))} block>Last Week</Button></Col>
                <Col xs={6} sm={6} md={4} lg={4} className="gutter-top-fixed-1"><Button className="fs-mini" color="success" onClick={((e) => this.shortcut(0))} block>Today</Button></Col>
                <Col xs={6} sm={6} md={4} lg={4} className="gutter-top-fixed-1"><Button className="fs-mini" color="default" onClick={((e) => this.shortcut(1))} block>Yesterday</Button></Col>
                <Col xs={6} sm={6} md={4} lg={4} className="gutter-top-fixed-1"><Button className="fs-mini" color="default" onClick={((e) => this.shortcut(2))} block>Week to Date</Button></Col>
                <Col xs={6} sm={6} md={4} lg={4} className="gutter-top-fixed-1"><Button className="fs-mini" color="default" onClick={((e) => this.shortcut(3))} block>Month to Date</Button></Col>
                <Col xs={6} sm={6} md={4} lg={4} className="gutter-top-fixed-1"><Button className="fs-mini" color="default" onClick={((e) => this.shortcut(4))} block>Year to Date</Button></Col>
                {this.props.user.admin ? (
                <Col xs={6} sm={6} md={4} lg={4} className="gutter-top-fixed-1"><Button className="fs-mini" color="default" onClick={((e) => this.shortcut(9))} block>Fiscal to Date</Button></Col>
                ) : null}
                {this.props.user.admin ? (
                <Col xs={6} sm={6} md={4} lg={4} className="gutter-top-fixed-1"><Button className="fs-mini" color="default" onClick={((e) => this.shortcut(7))} block>Season to Date</Button></Col>
                ) : null}
                {this.props.user.admin ? (
                <Col xs={6} sm={6} md={4} lg={4} className="gutter-top-fixed-1"><Button className="fs-mini" color="default" onClick={((e) => this.shortcut(8))} block>Since Reboot</Button></Col>
                ) : null}
              </Row>
            </div>

            {this.props.user.admin ? (
            <>
              <hr className="gutter-top-fixed-2" />

              <div className="gutter-top-fixed-2 text-center">
                <h6 className="fw-semi-bold">WEEKENDINGS</h6>
                {this.state ? (
                  <div>
                    <Slider
                      range
                      min = {-51}
                      max = {20}
                      onChange={((e) => this.sliderOnChange(e))}
                      defaultValue={this.state.sliderDefault}
                      allowCross={false}
                      marks={this.state.marks}
                    />
                    <div className="gutter-top-fixed-4 gutter-left-fixed-4 gutter-right-fixed-4">
                      <Button color={this.state.sliderDisabled ? 'default' : 'success'} onClick={((e) => this.sliderSet())} block disabled={this.state.sliderDisabled}>{this.state.sliderRangeText}</Button>
                    </div>
                  </div>
                ) : null}
                
              </div>
            </>
            ) : null}
          </div>

        </div>
      </section>
    );
  }
}

function mapStateToProps(store) {
  return {
    filter: store.filter
  };
}

export default connect(mapStateToProps)(Dates);
