import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  Row
} from 'reactstrap';

import Widget from '../../../components/Widget';
import Loading from '../../../components/Loading';

import Select from 'react-select';

import { scurl } from '../../../core/utils.js';
import { filterUpdated, defaultTimespanV2, datespan, dateRangeDisplay, displayTrafficMetric } from '../../../core/utils';

import DataPointCard from '../../cards/DataPoint.js';
import ChartLegend from '../../cards/Legend.js';

import DashCam from '../../universal/DashCam/DashCam.js';

class WidgetContentSessions extends React.Component {

  static propTypes = {
    print: PropTypes.bool,
    dashboard: PropTypes.bool,
    timespan: PropTypes.string,
    fixed_years: PropTypes.number,
    lookback: PropTypes.number,
    product_id: PropTypes.number,
    traffic_metric: PropTypes.string,
  };

  static defaultProps = {
    print: false,
    dashboard: false,
    timespan: 'days',
    fixed_years: 3,
    lookback: 30,
    traffic_metric: 'sessions'
  };

  constructor(props) {
    super(props);

    let dorw = defaultTimespanV2(this.props.filter);
    let drd = dateRangeDisplay(this.props.filter.dates, 'MMMM dd, yyyy');
    
    let metric_options = [
      { value: 'sessions', label: 'Sessions' },
      { value: 'purchases', label: 'Purchases' },
    ]

    let metric_selected = metric_options[0];

    this.state = {
      isLoaded: false,
      isError: false,
      chart_timespan: dorw,
      fixed_years: this.props.fixed_years,
      dateRangeDisplay: drd,
      col_lg: 6,
      traffic_metric: this.props.traffic_metric,
      metric_options: metric_options,
      metric_selected: metric_selected
    }

    this._isMounted = false;
    this.loadData = this.loadData.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    this.loadData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if(filterUpdated(prevProps.filter, this.props.filter, 'SESSIONS WIDGET')) {
      this.loadData();
    } else {
      if(prevProps.skai_filter !== this.props.skai_filter) {
        this.loadData();
      }
      if(prevProps.print !== this.props.print) {
        this.setState({
          col_lg: 12
        });
      }
    }
  }

  componentDidCatch(error, info) {
    if(this._isMounted) {
      this.setState({
        error: {
          didCatch: true,
          error: error,
          info: info,
          status: error.status || -99,
          message: error.message || 'Error occured'
        }
      });
    }
  }

  loadData() {

    var lookback = datespan(this.props.filter);
    if(lookback < 7) {
      //lookback = 7;
    }
    this.setState({
      isLoaded: false,
      results: [],
      lookback: parseInt(lookback)
    }, function() {

      var opts = {
        path: '/v2/ga/sessions',
        type: 'universal',
        years: this.props.fixed_years,
        //lookback: this.state.lookback
      };

      if(this.props.product_id) {
        opts.product_id = this.props.product_id;
      }

      scurl(opts, function(err, results) {
        if(this._isMounted) {
          if(err) {
            this.setState({
              error: {
                json: err,
                status: err.status || -99,
                message: err.message || 'Error occured'
              }
            });
          } else {

            if(results) {
              this.setState({
                isLoaded: true,
                results: results,
              }, function() {
                this.setDashCam();
              });
            } else {
              this.setState({
                error: {
                  json: 'No data returned',
                  status: -99,
                  message: 'Error occured: No data returned'
                }
              });
            }
          }
        } else {
          console.log('handled unmount');
        }
      }.bind(this));
    });
  }

  changeChartTimespan = () => { 
    var change_display = this.state.chart_timespan;
    if(change_display === 'weeks') {
      change_display = 'days';
    } else {
      change_display = 'weeks';
    }
    this.setState({
      chart_timespan: change_display
    });
  }

  setDashCam() {

    let dashCamData = {
      results: this.state.results,
      headline: {
        title: this.state.traffic_metric,
        value: this.state.results.totals[0][this.state.traffic_metric].total,
        format: 'X,',
        yoy: parseFloat((this.state.results.totals[0][this.state.traffic_metric].total-this.state.results.totals[1][this.state.traffic_metric].total)/this.state.results.totals[1][this.state.traffic_metric].total)
      },
      panels: [
        {
          title: 'Paid Search',
          value: this.state.results.totals[0][this.state.traffic_metric].cpc,
          format: 'X,',
          yoy: parseFloat((this.state.results.totals[0][this.state.traffic_metric].cpc-this.state.results.totals[1][this.state.traffic_metric].cpc)/this.state.results.totals[1][this.state.traffic_metric].cpc)
        },
        {
          title: 'Organic',
          value: this.state.results.totals[0][this.state.traffic_metric].organic,
          format: 'X,',
          yoy: parseFloat((this.state.results.totals[0][this.state.traffic_metric].organic-this.state.results.totals[1][this.state.traffic_metric].organic)/this.state.results.totals[1][this.state.traffic_metric].organic)
        },
        {
          title: 'Direct',
          value: this.state.results.totals[0][this.state.traffic_metric].direct,
          format: 'X,',
          yoy: parseFloat((this.state.results.totals[0][this.state.traffic_metric].direct-this.state.results.totals[1][this.state.traffic_metric].direct)/this.state.results.totals[1][this.state.traffic_metric].direct)
        },
        {
          title: 'Social',
          value: this.state.results.totals[0][this.state.traffic_metric].social,
          format: 'X,',
          yoy: parseFloat((this.state.results.totals[0][this.state.traffic_metric].social-this.state.results.totals[1][this.state.traffic_metric].social)/this.state.results.totals[1][this.state.traffic_metric].social)
        },
        {
          title: 'Email',
          value: this.state.results.totals[0][this.state.traffic_metric].email,
          format: 'X,',
          yoy: parseFloat((this.state.results.totals[0][this.state.traffic_metric].email-this.state.results.totals[1][this.state.traffic_metric].email)/this.state.results.totals[1][this.state.traffic_metric].email)
        },
        {
          title: 'Other',
          value: this.state.results.totals[0][this.state.traffic_metric].other_referrals,
          format: 'X,',
          yoy: parseFloat((this.state.results.totals[0][this.state.traffic_metric].other_referrals-this.state.results.totals[1][this.state.traffic_metric].other_referrals)/this.state.results.totals[1][this.state.traffic_metric].other_referrals)
        }
      ]
    };

    this.setState({
      ...this.state,
      dashCamData: dashCamData
    });
  };

  changeMetric = (selected) => { 
    
    // let metric = this.state.traffic_metric;
    // switch(selected.value) {
    //   case 0:
    //     metric = 'sessions';
    //     break;
    //   case 1:
    //     metric = 'purchases';
    //     break;
    //   default:
    //     break;
    // }

    this.setState({
      traffic_metric: selected.value,
      metric_selected: selected,
    }, function() {
      this.setDashCam();
    });
  };

  render() {

    var title = <h5>Title</h5>
    this.props.dashboard ? (
      title = <h5>
        <span>Traffic</span>
        <small className="text-muted pl-2">excludes checkout</small>
      </h5>
    ) : (
      title = <h5><span className="text-danger">Website Sessions</span> <small className="text-muted">Unique starts on Broadway.com by source (excludes affiliates and checkout pages). Charts are displayed by <button className="header-link" onClick={() => this.changeChartTimespan()}>{this.state.chart_timespan === 'days' ? ('day') : ('week')}</button>.</small></h5>
    )

    return (

      this.props.dashboard ? (

        <Widget
          className="mb-0 h-100"
          bodyClass={`mt p-4`}
          title={
            <div className="d-flex justify-content-between flex-wrap">
              <h5 className="pt-1"><span>Site {displayTrafficMetric(this.state.traffic_metric)}</span><small className="text-muted pl-2">by source vs prior year</small></h5>
              {this.state ? (
                <Select
                  value={this.state.metric_selected}
                  onChange={(event) => this.changeMetric(event) }
                  options={this.state.metric_options}
                  placeholder='Filter results...'
                  isMulti={false}
                  className="form-control-sm p-0 m-0"
                /> 
              ) : null}
            </div>
          }
        >
          <Loading loading={!this.state.isLoaded} error={this.state.error} pad={10} />
          <DashCam 
            isLoaded={this.state.isLoaded} 
            print={this.props.print} 
            results={this.state.results} 
            title="Traffic" 
            description="versus prev year" 
            lookback={this.state.lookback} 
            payload={this.state.dashCamData}
            //more={{url: '/app/content/4'}}
          />
        </Widget>

      ) : (

        <div className="page-divide">

          <Widget title={title}>

            <Loading loading={!this.state.isLoaded} error={this.state.error} pad={10} />

            {this.state.isLoaded ? (
              <>

                <ChartLegend print={this.props.print} years={this.state.fixed_years} className="gutter-top-fixed-4" lookback={this.state.lookback} />

                <Row className="gutter-top-fixed-2 gutter-bottom-fixed-2">
                  <DataPointCard print={this.props.print} results={this.state.results} title="Total" parent="sessions" field="total" lookback={this.state.lookback} format="XM" timespan={this.state.chart_timespan} lg={this.state.col_lg} />
                  <DataPointCard print={this.props.print} results={this.state.results} title="CPC" parent="sessions" field="cpc" lookback={this.state.lookback} format="XM" timespan={this.state.chart_timespan} lg={this.state.col_lg} />
                  <DataPointCard print={this.props.print} results={this.state.results} title="Organic" parent="sessions" field="organic" lookback={this.state.lookback} format="XM" timespan={this.state.chart_timespan} lg={this.state.col_lg} />
                  <DataPointCard print={this.props.print} results={this.state.results} title="Direct" parent="sessions" field="direct" lookback={this.state.lookback} format="XM" timespan={this.state.chart_timespan} lg={this.state.col_lg} />
                  <DataPointCard print={this.props.print} results={this.state.results} title="Social" parent="sessions" field="social" lookback={this.state.lookback} format="XM" timespan={this.state.chart_timespan} lg={this.state.col_lg} />
                  <DataPointCard print={this.props.print} results={this.state.results} title="Email" parent="sessions" field="email" lookback={this.state.lookback} format="XM" timespan={this.state.chart_timespan} lg={this.state.col_lg} />
                  <DataPointCard print={this.props.print} results={this.state.results} title="3rd Party Sites" parent="sessions" field="other_referrals" lookback={this.state.lookback} format="XM" timespan={this.state.chart_timespan} lg={this.state.col_lg} />
                </Row>
              </>
            ) : null}
          </Widget>


        </div>

      )
      
    );
  }

}

function mapStateToProps(state) {
  return {
    filter: state.filter
  };
}

export default withRouter(connect(mapStateToProps)(WidgetContentSessions));
