import React, { Component } from 'react';
import cx from 'classnames';
import { Button, Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Widget from '../Widget';

import Select from 'react-select';

import { filterUpdated, filterCount, filterState} from '../../core/utils';
import { updateFilter } from '../../actions/filter';

import { surl } from '../../core/utils.js';

import s from './FilterThin.module.scss'; // eslint-disable-line

class FilterThin extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    user: PropTypes.object
  };

  static defaultProps = {
  };

  constructor(props) {
    super(props);
  
    this.state = { 
      isOpened: false,
      run: false,
      filterCount: 0,
      filterState: null,
      isLoaded: false
    };

    this.setCount = this.setCount.bind(this);
    this.clearAll = this.clearAll.bind(this);
    this.applyChanges = this.applyChanges.bind(this);

    this.setFilters = this.setFilters.bind(this);
    this.resetFilters = this.resetFilters.bind(this);

    this.handleChange = this.handleChange.bind(this);
  }

  toggleFilter = () => {
    this.setState({
      isOpened: !this.state.isOpened,
    });
  };

  componentDidMount() {
    this.setCount(this.props.filter);
    let fs = filterState(this.props.filter);
    this.setState({
      filterState: fs
    }, function() {
      this.setFilters();
    });
  }

  componentDidUpdate(prevProps) {
    if(filterUpdated(prevProps.filter, this.props.filter, 'FILTER COMPONENT')) {
      this.setCount(this.props.filter);
      var fs = filterState(this.props.filter);
      this.setState({
        filterState: fs
      }, function() {
        if(this.state.filterCount === 0) {
          this.resetFilters();
        }
      });
    }
  }

  setCount = (filter) => {
    var count = filterCount(filter);
    this.setState({
      filterCount: count,
    });
  }

  clearAll = () => {
    
    var before = Object.assign({}, this.props.filter);

    before.limit = {
			engagements: [],
      owners: [],
			sources: [],
			devices: [],
      countries: [],
      experiments: [],
      mediums: [],
      set: true
    };

		before.exclude = {
			engagements: [],
      owners: [],
			sources: [],
			refunds: false,
			countries: [],
      mediums: [],
      set: true
    };

    this.props.dispatch(updateFilter(before));

    this.resetFilters();
    this.toggleFilter();
  }

  checkLimitDup = (type) => {
    let include = [];
    for(var included of this.state[type].selected) {
      include.push({
        id: included.value,
        label: included.label
      });
    }
    return include;
  }

  applyChanges = () => {

    var filter = JSON.parse(JSON.stringify(this.props.filter));

    //TRANSACTION TYPES
    switch(this.state.transaction_types.selected.value) {
      case 0:
        filter.limit.refunds = false;
        filter.exclude.refunds = false;
        break;
      case 1:
        filter.limit.refunds = false;
        filter.exclude.refunds = true;
        break;
      case 2:
        filter.limit.refunds = true;
        filter.exclude.refunds = false;
        break;
      default:
        break;
    }

    filter.limit.engagements = this.checkLimitDup('engagements');
    filter.limit.sources = this.checkLimitDup('sources');
    filter.limit.devices = this.checkLimitDup('devices');
    filter.limit.owners = this.checkLimitDup('owners');
    filter.limit.mediums = this.checkLimitDup('mediums');

    filter.limit.chargeback = this.state.chargeback;

    this.props.dispatch(updateFilter(filter));
    this.setState({ 
      ...this.state,
      hasChanged: false,
    }, function() {
      this.toggleFilter();
    });
  }

  setFilters = () => {

    let devices = [
      { value: 'desktop', label: 'Desktop' },
      { value: 'mobile', label: 'Mobile' },
      { value: 'tablet', label: 'Tablet' },
    ];

    let sources = [
      { value: 'BROADWAY.COM', label: 'Broadway.com' },
      { value: 'NYC.COM', label: 'NYC.com' },
      { value: 'BROADWAYBOX', label: 'BroadwayBox' },
      { value: 'TIME OUT AMERICA, LLC', label: 'Timeout NY' }
    ];

    let owners = [
      { value: 'Nederlander-Organization', label: 'Nederlander' },
      { value: 'Shubert-Organization', label: 'Shubert' },
      { value: 'Jujamcyn-Theaters', label: 'Jujamcyn' },
      { value: 'Disney-Theatrical', label: 'Disney' },
      { value: 'Ambassador-Theatre-Group', label: 'ATG' },
      { value: 'Roundabout-Theatre-Company', label: 'Roundabout' },
      { value: 'Second-Stage-Theater', label: '2nd Stage' },
      { value: 'Manhattan-Theatre-Club', label: 'MTC' },
      { value: 'Independent-Theaters', label: 'Independent' }
    ];

    let mediums = [
      { value: 'cpc', label: 'Paid Search' },
      { value: 'organic', label: 'Organic Search' },
      { value: 'email', label: 'Email' },
      { value: 'display', label: 'Display Ad' },
      { value: 'referral', label: 'Referral' },
      { value: 'banner', label: 'Banner Ad' },
      { value: 'paid-social', label: 'Paid Social' }
    ];

    let chargeback = false;
    if(this.props.filter.limit.chargeback) {
      chargeback = this.props.filter.limit.chargeback;
    }

    let transaction_types = [
      { value: 0, label: 'Sales & Refunds' },
      { value: 1, label: 'Sales Only' },
      { value: 2, label: 'Refunds Only' },
    ];

    this.setState({ 
      ...this.state,
      isLoaded: true,
      hasChanged: false,
      transaction_types: {
        items: transaction_types,
        selected: transaction_types[0]
      },
      sources: {
        items: sources,
        selected: this.props.filter.limit.sources
      },
      devices: {
        items: devices,
        selected: this.props.filter.limit.devices
      },
      owners: {
        items: owners,
        selected: this.props.filter.limit.owners
      },
      mediums: {
        items: mediums,
        selected: this.props.filter.limit.mediums
      },
      chargeback: chargeback
    }, function() {
      this.setShows();
    });
  }

  setShows() {

    var opts = {
      path: '/v2/products?broadway=1&active=1',
      type: 'simple'
    };

    var url = surl(opts);
    console.log(url);

    fetch(url)
      .then(res => res.json())
      .then(
        (result) => {

          var items = [];
          var selectedItems = [];

          for(var product of result.results.products) {
            for(var limited of this.props.filter.limit.engagements) {
              if(product.id === limited.id) {
                var e = {
                  value: product.id, 
                  label: product.name,
                  poster_id: product.poster_id
                };
                selectedItems.push(e);
                break;
              }
            }
            var e2 = {
              value: product.id, 
              label: product.name,
              poster_id: product.poster_id
            };
            items.push(e2);
          }

          this.setState({
            ...this.state,
            engagements: {
              ...this.state.engagements,
              isLoaded: true,
              items: items,
              selected: selectedItems
            }
          });

        },
        (error) => {
          this.setState({
            ...this.state,
            engagements: {
              ...this.state.engagements,
              isLoaded: true,
              error: error
            }
        });
      }
    );
  }

  resetFilters = () => {
    this.setState({ 
      ...this.state,
      isLoaded: true,
      hasChanged: false,
      transaction_types: {
        ...this.state.transaction_types,
        selected: this.state.transaction_types.items[0]
      },
      engagements: {
        ...this.state.engagements,
        selected: []
      },
      sources: {
        ...this.state.transaction_types,
        selected: []
      },
      devices: {
        ...this.state.devices,
        selected: []
      },
      owners: {
        ...this.state.owners,
        selected: []
      },
      mediums: {
        ...this.state.mediums,
        selected: []
      },
      chargeback: false
    });
  }

  handleChange = (selectedOptions, e) => {
    this.setState({ 
      ...this.state,
      hasChanged: true,
      [e.name]: {
        ...this.state[e.name],
        selected: selectedOptions
      }
    });
  };

  handleCheckbox = (e) => {
    this.setState({ 
      ...this.state,
      hasChanged: true,
      [e.target.id]: e.target.checked
    });
  };

  render() {
    const { isOpened } = this.state;

    return (
      <div className={cx(s.themeHelper, { [s.themeHelperOpened]: isOpened })}>

        {this.state && this.state.isLoaded ? (
        <>

          {this.state.filterCount > 0 ? (
            <div className={`${s.themeHelperBtnFiltered} bg-danger helper-button`} onClick={this.toggleFilter}>
                <div className="text-white">
                  <h5><i className="fa-light fa-filter-list fa-2xl gutter-top-fixed-1 gutter-bottom-fixed-1" /></h5>
                </div>
            </div>
          ) : (
            <div className={`${s.themeHelperBtn} bg-primary helper-button`} onClick={this.toggleFilter}>
                <div className="text-white">
                  <i className="fa-light fa-filter-list fa-2xl gutter-top-fixed-1 gutter-bottom-fixed-1" />
                </div>
            </div>
          )}

          <Widget className={s.themeHelperContent} >

            <Row>
              <Col xs={12} sm={12} md={6} lg={6} xl={6}>
                <Button size="sm" color={this.state.hasChanged ? 'success' : 'default'} className="mr-1" onClick={((e) => this.applyChanges(e))} disabled={!this.state.hasChanged}>Apply Changes</Button>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} xl={6} className="text-right">
                {this.state.filterCount > 0 ? (
                  <Button size="sm" color="primary" className="text-white" onClick={((e) => this.clearAll(e))}><i className="fa-light fa-refresh" />&nbsp;Reset</Button>
                ) : null }
              </Col>
            </Row>

            <hr/>

            {this.props.user.permissions.filter.filters.transaction_types ? (
              <>
                <h6 className="gutter-top-fixed-2"><strong>SALES/REFUNDS</strong></h6>      
                <Select
                  value={this.state.transaction_types.selected}
                  onChange={this.handleChange}
                  options={this.state.transaction_types.items}
                  placeholder='Select transaction type...'
                  isMulti={false}
                  name="transaction_types"
                /> 
              </>
            ) : null}

            {this.props.user.permissions.filter.filters.engagements && this.state.engagements && this.state.engagements.isLoaded ? (
              <>
                <h6 className="gutter-top-fixed-2"><strong>SHOWS</strong></h6>      
                <Select
                  value={this.state.engagements.selected}
                  onChange={this.handleChange}
                  options={this.state.engagements.items}
                  placeholder='Select shows...'
                  isMulti={true}
                  name="engagements"
                /> 
              </>
            ) : null}

            {this.props.user.permissions.filter.filters.sources ? (
              <>
                <h6 className="gutter-top-fixed-2"><strong>WEBSITES</strong></h6>      
                <Select
                  value={this.state.sources.selected}
                  onChange={this.handleChange}
                  options={this.state.sources.items}
                  placeholder='All Websites'
                  isMulti={true}
                  name="sources"
                /> 
              </>
            ) : null}

            {this.props.user.permissions.filter.filters.devices ? (
              <>
                <h6 className="gutter-top-fixed-2"><strong>DEVICES</strong></h6>      
                <Select
                  value={this.state.devices.selected}
                  onChange={this.handleChange}
                  options={this.state.devices.items}
                  placeholder='All Devices'
                  isMulti={true}
                  name="devices"
                /> 
              </>
            ) : null}

            {this.props.user.permissions.filter.filters.owners ? (
              <>
                <h6 className="gutter-top-fixed-2"><strong>THEATER OWNERS</strong></h6>      
                <Select
                  value={this.state.owners.selected}
                  onChange={this.handleChange}
                  options={this.state.owners.items}
                  placeholder='All Theater Owners'
                  isMulti={true}
                  name="owners"
                /> 
              </>
            ) : null}

            {this.props.user.permissions.filter.filters.mediums ? (
              <>
                <h6 className="gutter-top-fixed-2"><strong>TRAFFIC SOURCES</strong></h6>      
                <Select
                  value={this.state.mediums.selected}
                  onChange={this.handleChange}
                  options={this.state.mediums.items}
                  placeholder='All Traffic Sources'
                  isMulti={true}
                  name="mediums"
                /> 
              </>
            ) : null}

            {this.props.user.admin ? (
            <>
              <hr/>

              <div className="form-check">
                <input className="form-check-input" type="checkbox" value="" id="chargeback" name="chargeback" onClick={((e) => this.handleCheckbox(e))} checked={this.state.chargeback} onChange={e => {}} />
                <label className="form-check-label" htmlFor="chargeback">
                  <strong>CHARGEBACKS</strong>
                  <p className="small">Transactions that resulted in chargeback (excludes refunds).</p>
                </label>
              </div>
            </>
            ) : null}

          </Widget>
        </>
        ) : null}

      </div>
    );
  }
}

function mapStateToProps(store) {
  return {
    filter: store.filter
  };
}

export default connect(mapStateToProps)(FilterThin);
